import { ReorderWidthGroupsArgs } from '../types/reorderWidthGroupsArgs'
import { groupWidgets } from './groupWidgets'

export const reorderHalfWidthGroups = ({ destinationIndex, sourceIndex, widgets }: ReorderWidthGroupsArgs) => {
  const grouped = groupWidgets(widgets).flat()

  const [sourceItem] = grouped.splice(sourceIndex, 1)
  grouped.splice(destinationIndex, 0, sourceItem)

  return groupWidgets(grouped).flat()
}
