import { Widget } from '../../../../../../../types/widget'
import { Widgets } from '../../../../../../../types/widgets'
import { isFullWidthWidget } from '../../../../../../../utils/isFullWidthWidget'

interface GroupWidget {
  group: Widget[]
  minIndex: number
}

export const groupWidgets = (widgets: Widgets['visible']) => {
  const groups: GroupWidget[] = []
  let currentSubgroup: GroupWidget | undefined

  for (const [index, widget] of widgets.entries()) {
    if (isFullWidthWidget(widget.id)) {
      groups.push({
        group: [{ id: widget.id }],
        minIndex: index,
      })
    } else {
      if (!currentSubgroup || currentSubgroup.group.length === 2) {
        currentSubgroup = {
          group: [{ id: widget.id }],
          minIndex: index,
        }
        groups.push(currentSubgroup)
      } else {
        currentSubgroup.group.push({ id: widget.id })
      }
    }
  }

  groups.sort((a, b) => a.minIndex - b.minIndex)

  return groups.map((item) => item.group)
}
