import { Carousel, useModal } from '@design-system'

import { ModalId } from '../../../../enums/modalId'
import { useDashboardSettings } from '../../contexts/dashboardSettingsContext'
import { CarouselItem } from './elements/CarouselItem'
import { useCarouselUpsellWidgets } from './hooks/useCarouselUpsellWidgets'

export const CarouselUpsell = () => {
  const { carouselUpsellWidgets } = useCarouselUpsellWidgets()
  const { isOpen } = useModal(ModalId.HelpModal)
  const { isEditMode } = useDashboardSettings()

  return (
    <Carousel pauseAutoSlide={isOpen || isEditMode}>
      {carouselUpsellWidgets.map(({ id, subtitle, description, title, backgroundColor, img, renderButton }) => (
        <CarouselItem
          key={id}
          subtitle={subtitle}
          description={description}
          title={title}
          backgroundColor={backgroundColor}
          img={img}
          renderButton={renderButton}
        />
      ))}
    </Carousel>
  )
}
