import React, { useCallback, useMemo } from 'react'

import { widgetToComponent } from '../../../../../../constants/widgetIdToComponent'
import { useDashboardSettings } from '../../../../../../contexts/dashboardSettingsContext'
import { useApplyAllFiltersWidgets } from '../../../../../../hooks/useApplyAllFiltersWidgets'
import { useWidgetsActions } from '../../../../../../hooks/useWidgetsActions'
import { WidgetId } from '../../../../../../types/widgetId'
import { isFullWidthWidget } from '../../../../../../utils/isFullWidthWidget'
import { ToggleVisibilityWidgetButton } from '../../../../../ToggleVisibilityWidgetButton'
import * as Styled from './styles'

export const HiddenWidgetsList = () => {
  const { widgets } = useDashboardSettings()
  const { showWidget } = useWidgetsActions()
  const { applyAllFilters } = useApplyAllFiltersWidgets()

  const handleShowWidgetClick = useCallback(
    (id: WidgetId) => {
      showWidget(id)
    },
    [showWidget],
  )

  const filteredWidgets = useMemo(() => {
    return applyAllFilters(widgets)
  }, [applyAllFilters, widgets])

  return filteredWidgets.hidden.map(({ id }) => (
    <Styled.HiddenWidgetContainer key={id} data-testid={`hidden-widget-${id}`} isFullWidth={isFullWidthWidget(id)}>
      <ToggleVisibilityWidgetButton handleClick={() => handleShowWidgetClick(id)} />
      {widgetToComponent[id]}
    </Styled.HiddenWidgetContainer>
  ))
}
