import { BorderRadius, Color } from '@design-system'

import styled from '@emotion/styled'

import { ToggleVisibilityWidgetButtonContainer } from '../../../../../ToggleVisibilityWidgetButton/styles'
import { getWidgetContainerStyles } from '../../../../utils/getWidgetContainerStyles'

interface HiddenWidgetContainerProps {
  isFullWidth?: boolean
}

export const HiddenWidgetContainer = styled.div<HiddenWidgetContainerProps>`
  position: relative;
  grid-column: ${({ isFullWidth }) => getWidgetContainerStyles(isFullWidth)};
  border-radius: ${BorderRadius.L};
  box-shadow: 0 2px 1px ${Color.Gray30};

  & > *:not(${ToggleVisibilityWidgetButtonContainer}) {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover ${ToggleVisibilityWidgetButtonContainer} {
    opacity: 1;
    visibility: visible;
  }
`
