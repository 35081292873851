import { Button, Space, Text } from '@design-system'

import { useTranslation } from 'react-i18next'

import { EmberRoute } from '../../../../../../enums/emberRoute'
import { useEmberRouteUrl } from '../../../../../../hooks/routing/useEmberRouteUrl'
import { widgetId } from '../../../../constants/widgetId'
import { useWidgetsActions } from '../../../../hooks/useWidgetsActions'
import { BankIntegrationActionPoint } from '../../enums/bankIntegrationActionPoint'
import * as Styled from './styles'

export interface BankDisconnectionProps {
  accountId: string
}

export const BankDisconnection = ({ accountId }: BankDisconnectionProps) => {
  const { t } = useTranslation()
  const { url: reconciliationPageUrl } = useEmberRouteUrl(EmberRoute.Bank, accountId)
  const { trackDashboardActionPerformed } = useWidgetsActions()

  return (
    <Styled.BankDisconnectionWrapper>
      <Styled.ContentWrapper>
        <Styled.Header>
          <Styled.Title variant="displayM">{t('dashboard.widget.bank-integration.disconnected.title')}</Styled.Title>
          <Space />
          <Styled.DescriptionWrapper>
            <Text>{t('dashboard.widget.bank-integration.disconnected.info_text')}</Text>
          </Styled.DescriptionWrapper>
        </Styled.Header>
        <Button
          icon="plug"
          to={reconciliationPageUrl}
          onClick={() =>
            trackDashboardActionPerformed({
              action: BankIntegrationActionPoint.ConnectBank,
              widgetId: widgetId.BankIntegration,
            })
          }
        >
          {t('dashboard.widget.bank-integration.connect_bank')}
        </Button>
      </Styled.ContentWrapper>
    </Styled.BankDisconnectionWrapper>
  )
}
