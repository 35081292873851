import { TFunction } from 'i18next'
import { z } from 'zod'

import { MissingContactDetailsFormSchema } from '../types/MissingContactDetailsFormSchema'

export const getMissingContactDetailsFormSchema = (t: TFunction): MissingContactDetailsFormSchema => {
  return z.object({
    contactName: z.string(),
    contactIban: z.string(),
    contactAddress: z.string(),
  })
}
