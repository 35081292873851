import { ModalContextProvider } from '@design-system'

import { byAttrVal, byBooleanAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'

import { store } from '@modules-deprecated'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '@modules-deprecated/app/organization'
import { AnnualReport, AnnualReportPurchasingContextProvider } from '@views/annualReport'
import { EditSubscriptionParams } from '@views/settings/routes/OrganizationSubscriptionSettings/types'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { EmberEventFn } from '../../types/emberEventFn'

class AnnualReportWebComponent extends DOMModel {
  @byAttrVal accountingPackagePrice: number
  @byAttrVal cvrCompanyTypeShort: string
  @byAttrVal diyPrice: number
  @byAttrVal diyPriceOneOff: number
  @byAttrVal organizationid: string
  @byAttrVal year: number
  @byBooleanAttrVal hasUnusedCredits: boolean
  @registerEvent('navigate') navigate: Function
  @registerEvent('purchaseAccountingPackage') purchaseAccountingPackage: Function | undefined
  @registerEvent('purchaseDIY') purchaseDIY: Function | undefined
  @registerEvent('editSubscription') editSubscription: EmberEventFn
}

type AnnualReportProps = {
  accountingPackagePrice: string
  companyType: string
  cvrCompanyTypeShort: string
  diyPrice: string
  diyPriceOneOff: string
  hasUnusedCredits: boolean
  onEditSubscription: (detail: EditSubscriptionParams) => void
  onNavigate: EmberNavigate
  onPurchaseAccountingPackage: EmberEventFn
  onPurchaseDIY: EmberEventFn
  organizationid: string
  year: string
}

function AnnualReportModule({
  accountingPackagePrice,
  cvrCompanyTypeShort,
  diyPrice,
  diyPriceOneOff,
  hasUnusedCredits,
  onEditSubscription,
  onNavigate,
  onPurchaseAccountingPackage,
  onPurchaseDIY,
  organizationid,
  year,
}: AnnualReportProps): ReactElement {
  return (
    <ReduxProvider store={store}>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <EmberRouterContextProvider onNavigate={onNavigate}>
            <UserOrganizationContextProvider organizationId={organizationid}>
              <UserOrganizationSettingsContextProvider organizationId={organizationid}>
                <ModalContextProvider>
                  <AnnualReportPurchasingContextProvider
                    accountingPackagePrice={Number(accountingPackagePrice)}
                    companyTypeShort={cvrCompanyTypeShort}
                    diyPrice={Number(diyPrice)}
                    diyPriceOneOff={Number(diyPriceOneOff)}
                    handlePurchaseAccountingPackage={() => onPurchaseAccountingPackage({ detail: null })}
                    handlePurchaseDIY={() => onPurchaseDIY({ detail: null })}
                    hasUnusedCredits={hasUnusedCredits}
                    onEditSubscription={onEditSubscription}
                    year={Number(year)}
                  >
                    <AnnualReport />
                  </AnnualReportPurchasingContextProvider>
                </ModalContextProvider>
              </UserOrganizationSettingsContextProvider>
            </UserOrganizationContextProvider>
          </EmberRouterContextProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </ReduxProvider>
  )
}

const AnnualReportCustomElement = createCustomElement(AnnualReportModule, AnnualReportWebComponent, 'element')

customElements.get('annual-report-module') || customElements.define('annual-report-module', AnnualReportCustomElement)

export default AnnualReportCustomElement
