import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ContactBalance } from '@modules-deprecated/app/contacts'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useCreatePrepayment } from '@views/bills/hooks/useCreatePrePayment'
import { CreatePrepaymentResponseData } from '@views/bills/query-api'
import { Bill } from '@views/bills/types/bill'
import { getSubjectReferenceFromBillId } from '@views/bills/utils/getSubjectReferenceFromBillId'
import { Contact } from '@views/contacts/types/contact'

import { useSegment } from '../../../../../hooks'
import { formatDate } from '../../../../../utils'
import { useInvalidateBills } from '../../../hooks/useInvalidateBills'
import { notifyPaymentRegistrationError } from '../utils/notifyPaymentRegistrationError'

interface UseRegisterPrepaymentProps {
  paymentDate: Date | undefined
  bill: Bill | undefined
  contact: Contact | undefined
  contactBalance: ContactBalance | undefined
  onSuccess: (data: CreatePrepaymentResponseData) => void
}

export const useRegisterPrepayment = ({
  paymentDate,
  bill,
  contact,
  contactBalance,
  onSuccess,
}: UseRegisterPrepaymentProps) => {
  const [isProcessing, setIsProcessing] = useState(false)

  const { t } = useTranslation()
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const { invalidateBill, invalidateBills } = useInvalidateBills()

  const { create: createPrepayment } = useCreatePrepayment({
    onSuccess: (data) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      track('Bill Payment Created (Client)', { bill_id: bill?.id })
      invalidateBill(bill?.id)
      invalidateBills()
      setIsProcessing(false)
      onSuccess(data)
    },
    onError: () => {
      notifyPaymentRegistrationError(t)
      setIsProcessing(false)
    },
  })

  const registerContactBalancePayment = useCallback(() => {
    if (!bill?.balance || !contactBalance) {
      return
    }
    track('xxx - expenses - Prepayment modal - register payment clicked')
    setIsProcessing(true)
    const contactBalanceAmount = contactBalance?.amount ?? 0
    const prepaymentAmount = Math.min(bill.balance, contactBalanceAmount)
    const subjectReference = getSubjectReferenceFromBillId(bill.id)
    const payload = {
      amount: prepaymentAmount,
      associations: [
        {
          subjectReference,
        },
      ],
      contactId: contact?.id as string,
      entryDate: formatDate(paymentDate) as string,
      organizationId: organization?.id as string,
    }
    createPrepayment(payload)
  }, [bill?.balance, bill?.id, contact?.id, contactBalance, createPrepayment, organization?.id, paymentDate, track])

  return {
    registerContactBalancePayment,
    isProcessing,
  }
}
