import React, { createContext, ReactElement, useContext, useMemo } from 'react'

import { CvrCompanyTypeShort } from '@modules-deprecated/app/organization/enums/CvrCompanyTypeShort'
import { EditSubscriptionParams } from '@views/settings/routes/OrganizationSubscriptionSettings/types'

import { useOrganizationSubscription } from '../../../hooks'
import { FIRST_ANNUAL_REPORTS_SUBSCRIPTION_YEAR } from '../constants/firstAnnualReportsSubscriptionYear'
import { LAST_SOLD_OUT_ANNUAL_REPORTS_YEAR } from '../constants/lastSoldOutAnnualReportsYear'

export interface AnnualReportPurchasingContextState {
  accountingPackagePrice: number
  diyPrice: number
  diyPriceOneOff: number
  handlePurchaseAccountingPackage: () => void
  handlePurchaseDIY: () => void
  hasSubscriptionActive: boolean
  isAccountingPackageSoldOut: boolean
  isDIYAlreadyPurchased: boolean
  isDIYPurchaseDisabled: boolean
  isDIYSoldOut: boolean
  onEditSubscription: (event: EditSubscriptionParams) => void
  year: number
}

const AnnualReportPurchasingContext = createContext<AnnualReportPurchasingContextState>(
  {} as AnnualReportPurchasingContextState,
)

interface AnnualReportPurchasingContextProviderProps extends React.PropsWithChildren<{}> {
  accountingPackagePrice: number
  companyTypeShort: string
  diyPrice: number
  diyPriceOneOff: number
  handlePurchaseAccountingPackage: () => void
  handlePurchaseDIY: () => void
  hasUnusedCredits: boolean
  onEditSubscription: (event: EditSubscriptionParams) => void
  year: number
}

export const AnnualReportPurchasingContextProvider = ({
  accountingPackagePrice,
  children,
  companyTypeShort,
  diyPrice,
  diyPriceOneOff,
  handlePurchaseAccountingPackage,
  handlePurchaseDIY,
  hasUnusedCredits,
  onEditSubscription,
  year,
}: AnnualReportPurchasingContextProviderProps): ReactElement => {
  const { hasAnnualReportsSubscription } = useOrganizationSubscription()
  const hasSubscriptionActive = hasAnnualReportsSubscription && year >= FIRST_ANNUAL_REPORTS_SUBSCRIPTION_YEAR
  const isSoldOut = year <= LAST_SOLD_OUT_ANNUAL_REPORTS_YEAR
  const isLTD = companyTypeShort === CvrCompanyTypeShort.LTD
  const isDIYSoldOut = isSoldOut && isLTD && !hasUnusedCredits
  const isDIYAlreadyPurchased = isSoldOut && hasSubscriptionActive
  const isDIYPurchaseDisabled = isDIYSoldOut || isDIYAlreadyPurchased

  const value = useMemo(
    () => ({
      accountingPackagePrice,
      diyPrice,
      diyPriceOneOff,
      handlePurchaseAccountingPackage,
      handlePurchaseDIY,
      hasSubscriptionActive,
      isAccountingPackageSoldOut: isSoldOut,
      isDIYAlreadyPurchased,
      isDIYPurchaseDisabled,
      isDIYSoldOut,
      onEditSubscription,
      year,
    }),
    [
      accountingPackagePrice,
      diyPrice,
      diyPriceOneOff,
      handlePurchaseAccountingPackage,
      handlePurchaseDIY,
      hasSubscriptionActive,
      isSoldOut,
      isDIYAlreadyPurchased,
      isDIYPurchaseDisabled,
      isDIYSoldOut,
      onEditSubscription,
      year,
    ],
  )

  return <AnnualReportPurchasingContext.Provider value={value}>{children}</AnnualReportPurchasingContext.Provider>
}

export const useAnnualReportPurchasing = () => useContext(AnnualReportPurchasingContext)
