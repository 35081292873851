import { Spacing } from '@design-system'

import styled from '@emotion/styled'

import { getWidgetContainerStyles } from '../Widgets/utils/getWidgetContainerStyles'

export const DashboardSkeletonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
`

export const DashboardSkeletonBannerWrapper = styled.div`
  margin: ${Spacing.L} 0;
`

interface DashboardSkeletonGridItemProps {
  isFullWidth?: boolean
}

export const DashboardSkeletonGridItem = styled.div<DashboardSkeletonGridItemProps>`
  grid-column: ${({ isFullWidth }) => getWidgetContainerStyles(isFullWidth)};
`
