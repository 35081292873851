import { isBookkeepingHeroAllowed } from '@components'
import { AttachmentFile } from '@design-system'

import { fetchCompanies } from '@modules-deprecated/app/companies'
import { findContactsWithBohr } from '@modules-deprecated/app/contacts'
import { ChartOfAccountId } from '@modules-deprecated/app/organization/enums/chartOfAccountId'
import { Contact } from '@views/contacts/types/contact'

import { BohrScanResult } from '../../../../../types/bohrScanResult'
import { getApiCountryKey } from '../../../../../utils/getApiCountryKey'
import { getLocalDateFromDateString } from '../../../../../utils/getLocalDateFromDateString'
import { trackError } from '../../../../../utils/trackError'
import { BillFormPartial } from './formData'
import { getBohrScanResultFromAttachmentFile } from './getBohrScanResultFromAttachmentFile'
import { getBookkeepingTagsFromTagScores } from './getBookkeepingTagsFromTagScores'
import { getCombinedVendorProp } from './getCombinedVendorProp'

const TAG_SUGGESTIONS_AMOUNT = 3

interface GetBillFormFromBohrDataProps {
  attachmentFile?: AttachmentFile
  chartOfAccountId?: ChartOfAccountId
  countryId?: string
  locale: string
  organizationId: string
  withBookkeepingSuggestions?: boolean
}

export const getBillFormFromBohrData = async ({
  attachmentFile,
  chartOfAccountId,
  countryId,
  locale,
  organizationId,
  withBookkeepingSuggestions = true,
}: GetBillFormFromBohrDataProps): Promise<BillFormPartial> => {
  const bohrScanResult = getBohrScanResultFromAttachmentFile(attachmentFile)

  if (!bohrScanResult) {
    return {}
  }

  const {
    document_currency: currencyIdLegacy,
    document_due_date: dueDateLegacy,
    document_issue_date: issueDateLegacy,
    document_total_incl_vat: inclVatAmountLegacy,
    entities,
    supplier: supplierLegacy,
    tag_scores: tagScores,
  }: BohrScanResult = bohrScanResult
  const {
    currency: currencyData,
    due_date: dueDateData,
    issue_date: issueDateData,
    total_incl_vat: totalInclVatData,
    vendor: vendorData,
  } = entities || {}
  const currencyId = currencyData?.content || currencyIdLegacy
  const dueDate = dueDateData?.content || dueDateLegacy
  const billDate = issueDateData?.content || issueDateLegacy
  const inclVatAmount = totalInclVatData?.content || inclVatAmountLegacy
  const supplier = vendorData?.content || supplierLegacy

  const getVendorData = async (): Promise<Partial<Contact> | undefined> => {
    const name = supplier?.name
    const apiCountryKey = getApiCountryKey(countryId)
    const registrationNo = supplier?.registration_no || supplier?.vat_no

    /* Look by registration number only */
    const lookupCompanies = registrationNo ? await fetchCompanies(registrationNo, apiCountryKey) : { companies: [] }
    const lookupVendor = lookupCompanies?.companies?.[0]

    const contactSearchName = lookupVendor?.name || name

    if (contactSearchName) {
      try {
        const vendors = await findContactsWithBohr(organizationId, contactSearchName, registrationNo)

        if (vendors.length) {
          return vendors[0]
        }
      } catch (error) {
        trackError(error)
        return undefined
      }
    }

    return {
      ...getCombinedVendorProp({
        propName: 'registrationNo',
        lookupVendor,
        vendorKey: 'id',
        supplier,
        supplierKey: 'registration_no',
      }),
      ...getCombinedVendorProp({
        propName: 'vatNo',
        lookupVendor,
        vendorKey: 'vatNo',
        supplier,
        supplierKey: 'vat_no',
      }),
      ...getCombinedVendorProp({
        propName: 'countryId',
        lookupVendor: undefined,
        vendorKey: undefined,
        supplier,
        supplierKey: 'country',
      }), // No lookupVendor key for country
      ...getCombinedVendorProp({ propName: 'name', lookupVendor, vendorKey: 'name', supplier, supplierKey: 'name' }),
      ...getCombinedVendorProp({
        propName: 'cityText',
        lookupVendor,
        vendorKey: 'city',
        supplier,
        supplierKey: 'address_city',
      }),
      ...getCombinedVendorProp({
        propName: 'street',
        lookupVendor: undefined,
        vendorKey: undefined,
        supplier,
        supplierKey: 'address_street',
      }), // No lookupVendor key for street
      ...getCombinedVendorProp({ propName: 'phone', lookupVendor, vendorKey: 'phone', supplier, supplierKey: 'phone' }),
      ...getCombinedVendorProp({
        propName: 'zipcodeText',
        lookupVendor,
        vendorKey: 'zipcode',
        supplier,
        supplierKey: 'address_zip',
      }),
    }
  }

  const vendor = await getVendorData()

  const tagScoresLimited = tagScores ? tagScores.slice(0, TAG_SUGGESTIONS_AMOUNT) : undefined
  const isBookkeepingTagSuggestionsEnabled =
    withBookkeepingSuggestions && !!chartOfAccountId && isBookkeepingHeroAllowed(chartOfAccountId)
  const bookkeepingTagSuggestions =
    isBookkeepingTagSuggestionsEnabled && tagScoresLimited && tagScoresLimited.length
      ? await getBookkeepingTagsFromTagScores(tagScoresLimited, locale, chartOfAccountId, countryId)
      : undefined

  return {
    ...(billDate ? { billDate: getLocalDateFromDateString(billDate) } : {}),
    ...(bookkeepingTagSuggestions ? { bookkeepingTagSuggestions } : {}),
    ...(currencyId ? { currencyId } : {}),
    ...(dueDate ? { dueDate: getLocalDateFromDateString(dueDate) } : {}),
    ...(vendor ? { vendor } : {}),
    billLines: inclVatAmount ? [{ inclVatAmount }] : [],
  }
}
