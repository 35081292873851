import { ScannedCategory } from '@components'
import { AttachmentFile } from '@design-system'

import { getBohrScanResultFromAttachmentFile } from './getBohrScanResultFromAttachmentFile'

export const getScannedCategoryResultFromAttachmentsFiles = (
  attachmentsFiles?: AttachmentFile[],
): ScannedCategory[] => {
  // more than one file is not yet supported by ML Category Search endpoint
  if (!attachmentsFiles || !attachmentsFiles.length || attachmentsFiles.length > 1) {
    return []
  }

  try {
    const parsedData = getBohrScanResultFromAttachmentFile(attachmentsFiles[0])

    if (!parsedData?.tag_scores) {
      return []
    }

    /* eslint-disable @typescript-eslint/naming-convention */
    return parsedData.tag_scores.map(
      ({ tag_id, tag_name, score }): ScannedCategory => ({
        id: parseInt(tag_id, 10),
        name: tag_name,
        score,
      }),
    )
    /* eslint-enable @typescript-eslint/naming-convention */
  } catch (error) {
    return []
  }
}
