import { BorderRadius, Color } from '@design-system'

import { css, keyframes } from '@emotion/core'
import styled from '@emotion/styled'

import { ToggleVisibilityWidgetButtonContainer } from '../../../ToggleVisibilityWidgetButton/styles'
import { getWidgetContainerStyles } from '../../utils/getWidgetContainerStyles'

const shake = keyframes`
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  10% {
    transform: translate(0px, -1px) rotate(1deg);
  }
  20% {
    transform: translate(-1px, 0px) rotate(0deg);
  }
  30% {
    transform: translate(1px, 1px) rotate(-1deg);
  }
  40% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  50% {
    transform: translate(0px, 1px) rotate(1deg);
  }
  60% {
    transform: translate(-1px, 0px) rotate(0deg);
  }
  70% {
    transform: translate(1px, 0px) rotate(-1deg);
  }
  80% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  90% {
    transform: translate(0px, 1px) rotate(1deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
`

interface DraggableWidgetContainerProps {
  isEditMode: boolean
  isDragging: boolean
  isWidgetDragged: boolean
  isFullWidth?: boolean
}

export const DraggableWidgetContainer = styled.div<DraggableWidgetContainerProps>`
  ${({ isEditMode }) =>
    isEditMode &&
    css`
      position: relative;
      animation: ${shake} 1.5s ease-in infinite;

      &:hover {
        cursor: grab;
        animation: none;

        &::before {
          content: '';
          z-index: 1;
          top: 0;
          right: 0;
          position: absolute;
          opacity: 1;
          transform: translateX(0);
          width: 100%;
          height: 100%;
          background: linear-gradient(
            to left bottom,
            rgba(255, 255, 255, 0.9),
            rgba(255, 255, 255, 0.9),
            transparent 60%
          );
          border-radius: ${BorderRadius.L};
        }
      }

      &:active {
        cursor: grabbing;
        animation: none;
      }

      &:hover ${ToggleVisibilityWidgetButtonContainer} {
        opacity: 1;
        visibility: visible;
        cursor: pointer;
      }
    `};

  border-radius: ${BorderRadius.L};
  box-shadow: 0 2px 1px ${Color.Gray30};
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  z-index: ${({ isWidgetDragged }) => (isWidgetDragged ? 1 : 0)};
  grid-column: ${({ isFullWidth }) => getWidgetContainerStyles(isFullWidth)};
`
