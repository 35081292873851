import { Input } from '@design-system'

import { useFormContext } from '../../../../../../../../../../../../../../../../hooks'
import { MissingContactDetailsFormSchema } from '../../../../../../types/MissingContactDetailsFormSchema'

export const ContactName = () => {
  const { FormItem } = useFormContext<MissingContactDetailsFormSchema>()

  // TODO: add translations
  return (
    <FormItem
      name="contactName"
      label="Company Name"
      render={({ field: { onChange, ...rest } }) => {
        return (
          <Input
            {...rest}
            onChange={(contactName) => {
              onChange(contactName)
            }}
          />
        )
      }}
    />
  )
}
