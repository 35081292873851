import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Color } from '../../../../enums/color'
import { Direction } from '../../../../hooks/usePagination'
import { NavigationDirection } from '../../../../types/navigationDirection'
import { FilesPagination } from '../../../FilesPagination'
import { Flex } from '../../../Flex'
import { IconButton } from '../../../IconButton'
import { Tooltip } from '../../../Tooltip'
import { FloatingActionsMenuProps } from '../../types'
import { ElementsDivider } from './elements/ElementsDivider'
import { ZoomControls } from './elements/ZoomControls'
import * as Styled from './styles'

interface FloatingActionsPanelProps {
  actionsMenuItems?: (props: FloatingActionsMenuProps) => React.ReactNode
  activePageNumber: number
  canChangePage: (direction: Direction) => boolean
  handleChangePage: (direction: Direction) => void
  handleDeleteClick: () => void
  handleDownloadClick: () => void
  handleRotateClick: () => void
  isInView: boolean
  isLoading: boolean
  navigationDirection: NavigationDirection
  pagesAmount: number
  withDeleteButton?: boolean
  withDownloadButton?: boolean
  withPagination?: boolean
  withRotate?: boolean
  zoomable: boolean
}

const TOOLTIP_DELAY = 1000

export const FloatingActionsPanel = ({
  actionsMenuItems,
  activePageNumber,
  canChangePage,
  handleChangePage,
  handleDeleteClick,
  handleDownloadClick,
  handleRotateClick,
  navigationDirection,
  isInView,
  isLoading,
  pagesAmount,
  withDeleteButton,
  withDownloadButton,
  withPagination,
  withRotate,
  zoomable,
}: FloatingActionsPanelProps) => {
  const { t } = useTranslation()
  const [isDelayEnabled, setIsDelayEnabled] = useState(true)
  const [areTooltipsHidden, setAreTooltipsHidden] = useState(false)

  /* Tooltips have a common delay - if the user sticks enough time to see first/any tooltip,
  the rest are displayed with no delay */
  const handleTooltipShow = useCallback(() => {
    if (isDelayEnabled) {
      setIsDelayEnabled(false)
    }
  }, [isDelayEnabled])

  /* Tooltips are immediately hidden on click but then enabled for displaying with delay */
  const handleAnyClick = useCallback(() => {
    setAreTooltipsHidden(true)

    setTimeout(() => {
      setAreTooltipsHidden(false)
      setIsDelayEnabled(true)
    }, 0)
  }, [])

  /* Behaviour is reset on mouse leave */
  const handleMouseLeave = useCallback(() => {
    setAreTooltipsHidden(false)
    setIsDelayEnabled(true)
  }, [])

  const tooltipDelay = isDelayEnabled ? TOOLTIP_DELAY : 0

  const shouldDisplayDownloadSection = !actionsMenuItems && withDownloadButton

  const pagination = useMemo(
    () => (
      <>
        <Tooltip
          delay={tooltipDelay}
          label={`${t('attachments.navigate_between_pages')} (←/→)`}
          placement="top"
          onShow={handleTooltipShow}
          forceHidden={areTooltipsHidden}
        >
          <FilesPagination
            isLoading={isLoading}
            activePage={activePageNumber}
            amountOfPages={pagesAmount}
            canClickButton={canChangePage}
            onPageChange={handleChangePage}
            navigationDirection={navigationDirection}
          />
        </Tooltip>
      </>
    ),
    [
      activePageNumber,
      areTooltipsHidden,
      canChangePage,
      handleChangePage,
      handleTooltipShow,
      isLoading,
      navigationDirection,
      pagesAmount,
      t,
      tooltipDelay,
    ],
  )

  const zoomControls = useMemo(
    () => (
      <>
        <Tooltip
          delay={tooltipDelay}
          label={`${t('attachments.zoom_in_out')} (${t('attachments.scroll')})`}
          placement="top"
          onShow={handleTooltipShow}
          forceHidden={areTooltipsHidden}
        >
          <ZoomControls />
        </Tooltip>
      </>
    ),
    [areTooltipsHidden, handleTooltipShow, t, tooltipDelay],
  )

  const rotation = useMemo(
    () => (
      <>
        <Tooltip
          delay={tooltipDelay}
          label={t('rotate')}
          placement="top"
          onShow={handleTooltipShow}
          forceHidden={areTooltipsHidden}
        >
          <IconButton
            size="m"
            icon="rightArrowAroundSquare"
            onClick={handleRotateClick}
            title={t('rotate')}
            color={Color.Gray90}
          />
        </Tooltip>
      </>
    ),
    [areTooltipsHidden, handleRotateClick, handleTooltipShow, t, tooltipDelay],
  )

  const actionsMenu = useMemo(
    () => (
      <>
        {actionsMenuItems &&
          actionsMenuItems({
            areTooltipsHidden,
            handleDeleteClick,
            handleDownloadClick,
            onTooltipShow: handleTooltipShow,
            tooltipDelay,
            withDeleteButton,
            withDownloadButton,
          })}
      </>
    ),
    [
      actionsMenuItems,
      areTooltipsHidden,
      handleDeleteClick,
      handleDownloadClick,
      handleTooltipShow,
      tooltipDelay,
      withDeleteButton,
      withDownloadButton,
    ],
  )

  const downloadSection = useMemo(
    () => (
      <>
        <Tooltip
          delay={tooltipDelay}
          label={t('download')}
          placement="top"
          onShow={handleTooltipShow}
          forceHidden={areTooltipsHidden}
        >
          <IconButton
            icon="arrowPointingDown"
            onClick={handleDownloadClick}
            size="m"
            title={t('download')}
            color={Color.Gray90}
          />
        </Tooltip>
      </>
    ),
    [areTooltipsHidden, handleDownloadClick, handleTooltipShow, t, tooltipDelay],
  )

  const panelItems = [
    withPagination && pagination,
    zoomable && zoomControls,
    withRotate && rotation,
    actionsMenuItems && actionsMenu,
    shouldDisplayDownloadSection && downloadSection,
  ].filter(Boolean)

  return (
    <Styled.FloatingPanelWrapper isInView={isInView} onClick={handleAnyClick} onMouseLeave={handleMouseLeave}>
      <Flex alignItems="center">
        {panelItems.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && <ElementsDivider />}
            {item}
          </React.Fragment>
        ))}
      </Flex>
    </Styled.FloatingPanelWrapper>
  )
}
