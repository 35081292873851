import styled from '@emotion/styled'

interface CarouselTrackProps {
  currentIndex: number
  disableTransition?: boolean
}

export const CarouselTrack = styled.div<CarouselTrackProps>`
  display: flex;
  transform: translate3d(${({ currentIndex }) => `-${currentIndex * 100}%`}, 0, 0);
  transition: ${({ disableTransition }) =>
    disableTransition ? 'none' : 'transform 600ms cubic-bezier(0, 0, 0.58, 1)'};
  will-change: transform;
`

export const CarouselSlide = styled.div`
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`
