import { notify } from '@design-system'

import debounce from 'lodash/debounce'
import { useCallback, useMemo } from 'react'

import { useUpdateOrganizationSettings, useUserOrganization } from '@modules-deprecated/app/organization'
import { OrganizationSettingsError } from '@modules-deprecated/app/organization/types/organizationSettingsError'

import { NotificationKeys } from '../../../enums/notificationKeys'
import { ORGANIZATION_SETTINGS_WIDGETS_KEY } from '../constants/organizationSettingsWidgetsKey'
import { Widgets } from '../types/widgets'

export const useUpdateOrganizationSettingsWidgets = () => {
  const { organization } = useUserOrganization()
  const { update } = useUpdateOrganizationSettings({
    shouldRefetch: false,
    onError: (error: OrganizationSettingsError) => {
      notify({
        id: NotificationKeys.DashboardCustomization,
        message: error.errorMessage,
        variant: 'error',
      })
    },
  })

  const updateWidgets = useCallback(
    (widgets: Widgets) => {
      if (organization?.id) {
        update({
          key: ORGANIZATION_SETTINGS_WIDGETS_KEY,
          organizationId: organization.id,
          value: JSON.stringify(widgets),
        })
      }
    },
    [organization, update],
  )

  const debouncedUpdateWidgets = useMemo(() => debounce(updateWidgets, 250), [updateWidgets])

  const updateOrganizationSettingsWidgets = useCallback(
    ({ widgets, debounce = false }: { widgets: Widgets; debounce?: boolean }) => {
      if (debounce) {
        debouncedUpdateWidgets(widgets)
      } else {
        updateWidgets(widgets)
      }
    },
    [debouncedUpdateWidgets, updateWidgets],
  )

  return { updateOrganizationSettingsWidgets }
}
