import { Button, ButtonsGroup, Color, Space } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { EmberRoute } from '../../../../enums/emberRoute'
import { useEmberRouteUrl } from '../../../../hooks/routing/useEmberRouteUrl'
import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { widgetId } from '../../constants/widgetId'
import { Widget } from '../../elements/Widget'
import { useWidgetsActions } from '../../hooks/useWidgetsActions'
import { FindAccountantActionPoint } from './enums/findAccountantActionPoint'
import * as Styled from './styles'

export const FindAccountant = (): ReactElement => {
  const { t } = useTranslation()
  const { trackDashboardActionPerformed } = useWidgetsActions()

  const { url: settingsOrganizationUsersUrl } = useEmberRouteUrl(EmberRoute.SettingsOrganizationUsers)
  const findAccountantRoute = reactRoute.findAccountant.getMainRoute()
  const { url: findAccountantUrl } = useOrganizationViewUrl(findAccountantRoute)

  return (
    <Widget backgroundColor={Color.Green10}>
      <Styled.FindAccountantWrapper>
        <Styled.Header>
          <Space size="s" />
          <Styled.Title variant="displayS">{t('dashboard.widget.find_accountant.title')}</Styled.Title>
          <Space />
          <Styled.Content>{t('dashboard.widget.find_accountant.content')}</Styled.Content>
        </Styled.Header>
        <ButtonsGroup>
          <Button
            type="button"
            variant="secondary"
            to={findAccountantUrl}
            onClick={() =>
              trackDashboardActionPerformed({
                action: FindAccountantActionPoint.GetAFreeQuote,
                widgetId: widgetId.FindAccountant,
              })
            }
          >
            {t('dashboard.widget.find_accountant.find_bookkeeper.cta')}
          </Button>
          <Button
            type="button"
            variant="text"
            to={settingsOrganizationUsersUrl}
            onClick={() =>
              trackDashboardActionPerformed({
                action: FindAccountantActionPoint.InviteYourAccountant,
                widgetId: widgetId.FindAccountant,
              })
            }
          >
            {t('dashboard.widget.find_accountant.invite_bookkeeper.cta')}
          </Button>
        </ButtonsGroup>
        <Styled.Illustration />
      </Styled.FindAccountantWrapper>
    </Widget>
  )
}
