import { AttachmentFile } from '@design-system'

import { VoucherFile } from '@modules-deprecated/inbox/types'

import { BohrScanResult } from '../../../../../types/bohrScanResult'

export const getBohrScanResultFromAttachmentFile = (
  attachmentFile: AttachmentFile | VoucherFile | undefined,
): BohrScanResult | undefined => {
  if (!attachmentFile?.dataJson) {
    return undefined
  }

  return JSON.parse(attachmentFile.dataJson)
}
