import { OrganizationFeature } from '../enums/organizationFeature'

type BetaFeatures = {
  current: OrganizationFeature[]
  previous: OrganizationFeature[]
}

export const betaFeatures: BetaFeatures = {
  current: [
    OrganizationFeature.BillReconciliation,
    OrganizationFeature.AccountingBasisSetting,
    OrganizationFeature.FrodaLending,
    OrganizationFeature.EmailScanning,
  ],
  previous: [
    OrganizationFeature.NewBills,
    OrganizationFeature.NewBillsList,
    OrganizationFeature.CustomizeDashboard,
    OrganizationFeature.Payroll,
    OrganizationFeature.VatIntegration,
    OrganizationFeature.NewInvoicesList,
  ],
}
