import { Color } from '@design-system'

import React, { ReactElement } from 'react'

import { useOnboarding } from '../../../../hooks/useOnboarding'
import { Widget } from '../../elements/Widget'
import { PostChecklistContent } from './elements/PostChecklistContent'
import { PreChecklistContent } from './elements/PreChecklistContent'

export const GetStarted = (): ReactElement => {
  const { isLoading: isLoadingOnboarding, isOnboardingCompleted } = useOnboarding()

  return (
    <Widget backgroundColor={isOnboardingCompleted ? Color.Purple20 : Color.Lavender} isLoading={isLoadingOnboarding}>
      {isOnboardingCompleted ? <PostChecklistContent /> : <PreChecklistContent />}
    </Widget>
  )
}
