import { Color, Space, Text } from '@design-system'

import React from 'react'

import { getImgPath } from '../../../../../../utils/getImgPath'
import { type CarouselUpsellWidget } from '../../hooks/useCarouselUpsellWidgets'
import * as Styled from './styles'

export const CarouselItem = ({
  backgroundColor,
  description,
  subtitle,
  title,
  img,
  renderButton,
}: Omit<CarouselUpsellWidget, 'id' | 'scope'>) => {
  return (
    <Styled.CarouselWidgetContainer backgroundColor={backgroundColor}>
      <Styled.ContentContainer>
        <Styled.TextContainer>
          <Text weight="bold" uppercase color={Color.Gray90} variant="h4">
            {subtitle}
          </Text>
          <Space size="xs" />
          <Styled.Title>{title}</Styled.Title>
          <Space size="s" />
          <Styled.Description>{description}</Styled.Description>
          <Space size="l" />
        </Styled.TextContainer>
        <div>{renderButton()}</div>
      </Styled.ContentContainer>

      <Styled.ImageContainer>
        <Styled.Illustration src={getImgPath(img.src)} alt={img.alt} />
      </Styled.ImageContainer>
    </Styled.CarouselWidgetContainer>
  )
}
