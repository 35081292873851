import { Button, ButtonsGroup, Link, Space, Text } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useSegment, useUserflow } from '../../../../../../hooks'
import { getImageClassName } from '../../../../../../utils/getClassName'
import { USERFLOW_ONBOARDING_CHECKLIST_ID } from '../../../../constants/userflowIds'
import { widgetId } from '../../../../constants/widgetId'
import { useWidgetsActions } from '../../../../hooks/useWidgetsActions'
import { GetStartedActionPoint } from '../../enums/getStartedActionPoint'
import * as Styled from '../../styles'

export const PostChecklistContent = (): ReactElement => {
  const { t } = useTranslation()
  const { start } = useUserflow()
  const { track } = useSegment()
  const { hideWidget, trackDashboardActionPerformed } = useWidgetsActions()

  const handleWebinarsSignupClick = useCallback(() => {
    track('xxx - hamster - tile experiment button clicked', {
      button: 'book',
    })
    trackDashboardActionPerformed({ action: GetStartedActionPoint.Signup, widgetId: widgetId.GetStarted })
    window.open('https://my.demio.com/ref/9dpNEuygoIUhNsqd', '_blank')?.focus()
    hideWidget(widgetId.GetStarted)
  }, [hideWidget, track, trackDashboardActionPerformed])

  const handleOpenChecklist = useCallback(() => {
    start(USERFLOW_ONBOARDING_CHECKLIST_ID)
  }, [start])

  return (
    <Styled.WidgetContentWrapper>
      <Styled.Header>
        <Space size="s" />
        <Text variant="displayM">{t('dashboard.widget.get_started_completed.title')}</Text>
        <Space />
        <Styled.PostChecklistDescriptionWrapper>
          <Text>
            <Trans
              i18nKey="dashboard.widget.get_started_completed.description"
              components={{
                1: (
                  <Link withAnimation onClick={handleOpenChecklist}>
                    checklist
                  </Link>
                ),
              }}
            />
          </Text>
        </Styled.PostChecklistDescriptionWrapper>
      </Styled.Header>
      <Styled.Footer>
        <ButtonsGroup>
          <Button onClick={handleWebinarsSignupClick} variant="secondary">
            {t('dashboard.widget.get_started_completed.cta_book')}
          </Button>
        </ButtonsGroup>
      </Styled.Footer>
      <Styled.PostChecklistIllustration className={getImageClassName()} />
    </Styled.WidgetContentWrapper>
  )
}
