import { Divider, Space } from '@design-system'

import { useTranslation } from 'react-i18next'

import { HiddenWidgetsList } from './elements/HiddenWidgetsList'
import * as Styled from './styles'

export const HiddenWidgets = () => {
  const { t } = useTranslation()

  return (
    <>
      <Styled.SeparatorGridItem>
        <Space size="xxl" />
        <Divider>{t('dashboard.widget.hidden.title')}</Divider>
        <Space size="xxl" />
      </Styled.SeparatorGridItem>
      <HiddenWidgetsList />
    </>
  )
}
