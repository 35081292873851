import { useModalContentToggler } from '@components'
import { Button, ButtonsGroup, useModal } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useRegisterManualPayment } from '@views/bills/elements/BillReconciliationModal/hooks/useRegisterManualPayment'

import { ModalId } from '../../../../../../enums/modalId'
import { Side } from '../../../../../../enums/side'
import { useSegment } from '../../../../../../hooks'
import { formatDate } from '../../../../../../utils'
import { BillType } from '../../../../enums/billType'
import { useBillView } from '../../../BillReadableForm'
import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { useManualPayment } from '../../contexts/manualPaymentContext'
import { BillReconciliationModalStateId } from '../../enums/billReconciliationModalStateId'

export const ManualPaymentModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { billData } = useBillView()

  const { selectedDifferenceType, selectedAccountId, paymentDate } = useBillReconciliation()
  const { hasManualPaymentDifference, manualPaymentAmount, manualPaymentDifference } = useManualPayment()
  const { close: closeManualPaymentModal } = useModal(ModalId.BillReconciliationModal, {
    onClose: () => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      track('xxx - reporting - bills -  Pay and reconcile modal closed', { bill_id: billData?.bill.id })
    },
  })
  const { registerManualPayment, isProcessing } = useRegisterManualPayment({
    cashSide: billData?.bill.type === BillType.CreditNote ? Side.Debit : Side.Credit,
    selectedDifferenceType,
    selectedAccountId,
    manualPaymentAmount,
    manualPaymentDifference,
    paymentDate: formatDate(paymentDate),
    onSuccess: () => closeManualPaymentModal(),
  })

  const { setActiveState } = useModalContentToggler()

  const isMissingRequiredFields = useMemo(
    () => (hasManualPaymentDifference && !selectedDifferenceType) || !paymentDate || !manualPaymentAmount,
    [hasManualPaymentDifference, selectedDifferenceType, paymentDate, manualPaymentAmount],
  )

  const isEnterPaymentButtonDisabled = useMemo(
    () => isMissingRequiredFields || isProcessing,
    [isMissingRequiredFields, isProcessing],
  )

  const handleEnterPaymentButton = useCallback(() => {
    registerManualPayment()
  }, [registerManualPayment])

  const handleRegisterMultipleBillsButton = useCallback(() => {
    track('xxx - expenses - Manual payment modal - register mutliple bills clicked')

    setActiveState(BillReconciliationModalStateId.MultipleBills)
  }, [setActiveState, track])

  return (
    <ButtonsGroup>
      <Button onClick={handleRegisterMultipleBillsButton} variant="text" icon="papersPile">
        {t('bill.payment_reconcile.button.register_multiple_bills')}
      </Button>
      <Button onClick={handleEnterPaymentButton} disabled={isEnterPaymentButtonDisabled}>
        {t('bill.payment_reconcile.button.enter_payment')}
      </Button>
    </ButtonsGroup>
  )
}
