import { useEffect, useMemo } from 'react'

import { useUserOrganizationSettings } from '@modules-deprecated/app/organization'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

import { useOrganizationSubscription } from '../../../hooks'
import { useDashboardSettings } from '../contexts/dashboardSettingsContext'
import { useCompareWidgetsWithDefault } from './useCompareWidgetsWithDefault'
import { useDefaultWidgets } from './useDefaultWidgets'
import { usePrepareWidgets } from './usePrepareWidgets'
import { useUpdateOrganizationSettingsWidgets } from './useUpdateOrganizationSettingsWidgets'

export const useSynchronizeWidgets = () => {
  const {
    isLoading: isOrganizationSettingsLoading,
    isIdle: isOrganizationSettingsIdle,
    organizationSettings,
  } = useUserOrganizationSettings()
  const { preparedWidgets } = usePrepareWidgets()
  const { setWidgets } = useDashboardSettings()
  const { updateOrganizationSettingsWidgets } = useUpdateOrganizationSettingsWidgets()
  const { compareWidgetsWithDefault } = useCompareWidgetsWithDefault()
  const { subscriptionPlan } = useOrganizationSubscription()
  const defaultWidgets = useDefaultWidgets()

  const syncedWidgets = useMemo(() => {
    return compareWidgetsWithDefault(preparedWidgets)
  }, [compareWidgetsWithDefault, preparedWidgets])

  const isFetchingOrganizationSettings = isOrganizationSettingsLoading || isOrganizationSettingsIdle

  useEffect(() => {
    if (isFetchingOrganizationSettings) {
      return
    }

    if (subscriptionPlan === SubscriptionPlan.Free) {
      setWidgets(defaultWidgets)
      updateOrganizationSettingsWidgets({ widgets: defaultWidgets })
      return
    }

    if (syncedWidgets) {
      setWidgets(syncedWidgets)
    }
  }, [
    isFetchingOrganizationSettings,
    setWidgets,
    updateOrganizationSettingsWidgets,
    syncedWidgets,
    organizationSettings,
    defaultWidgets,
    subscriptionPlan,
  ])

  return { isFetchingOrganizationSettings }
}
