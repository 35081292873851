import { memo, ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import { FileComponentProps } from '../../types'
import * as Styled from './styles'

const HTML_IFRAME_INITIAL_HEIGHT = 1122

export const HtmlPreview = memo(({ onLoad, src }: FileComponentProps): ReactElement => {
  const [isHtmlLoaded, setIsHtmlLoaded] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const observerRef = useRef<MutationObserver | null>(null)
  const [height, setHeight] = useState(HTML_IFRAME_INITIAL_HEIGHT)

  const updateSize = useCallback(() => {
    const iframe = iframeRef.current

    if (iframe) {
      const docHeight = iframe.contentDocument?.body?.scrollHeight || HTML_IFRAME_INITIAL_HEIGHT

      if (docHeight !== height) {
        setHeight(docHeight)
      }
    }
  }, [height])

  const handleLoad = useCallback(() => {
    const iframe = iframeRef.current
    const body = iframe?.contentDocument?.body

    if (iframe && body) {
      updateSize()
      iframe.scrollIntoView()
      setIsHtmlLoaded(true)
      onLoad?.()

      observerRef.current = new MutationObserver(updateSize)
      observerRef.current.observe(body, { childList: true, subtree: true })
    }
  }, [onLoad, updateSize])

  useEffect(() => {
    const iframe = iframeRef.current

    if (iframe) {
      iframe.onload = handleLoad
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
        observerRef.current = null
      }

      if (iframe) {
        iframe.onload = null
      }
    }
  }, [handleLoad])

  return <Styled.HtmlIframe srcDoc={src} ref={iframeRef} height={height} isLoading={!isHtmlLoaded} scrolling="no" />
})
