import { Color } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { ReactComponent as ImageOverviewDKComponent } from '../../../../assets/images/dashboard-empty-state/dk/overview.svg'
import { ReactComponent as ImageOverviewENComponent } from '../../../../assets/images/dashboard-empty-state/en/overview.svg'
import { ReactComponent as ImageOverviewFRComponent } from '../../../../assets/images/dashboard-empty-state/fr/overview.svg'
import { LocaleCode } from '../../../../enums/localeCode'
import { QueryKeys } from '../../../../enums/queryKeys'
import { useLocale } from '../../../../hooks/useLocale'
import { EmptyStateImage } from '../../elements/EmptyStateImage'
import { Widget } from '../../elements/Widget'
import { useFiscalYearQueryParams } from '../../hooks/useFiscalYearQueryParams'
import { fetchEasyNumbers, StatsDataQueryParams } from '../../services/query-api'
import { BarChart, BarChartData } from './elements/BarChart'
import * as Styled from './styles'

const emptyImageByLanguage: Record<LocaleCode, ReactElement> = {
  [LocaleCode.DA]: <ImageOverviewDKComponent />,
  [LocaleCode.EN_US]: <ImageOverviewENComponent />,
  [LocaleCode.FR]: <ImageOverviewFRComponent />,
}

export const Overview = (): ReactElement => {
  const { t } = useTranslation()
  const { code = LocaleCode.EN_US } = useLocale()
  const localeCode = code as LocaleCode

  const { organization } = useUserOrganization()
  const { queryParams } = useFiscalYearQueryParams(organization?.id)

  const { data, isLoading: isDataLoading } = useQuery(
    [QueryKeys.EasyNumbers, organization?.id, queryParams],
    () => fetchEasyNumbers(organization?.id || '', queryParams || ({} as StatsDataQueryParams)),
    {
      enabled: !!organization && !!queryParams,
      refetchOnMount: 'always',
    },
  )

  const isLoading = !organization || isDataLoading
  const { revenue = 0, expense = 0 } = data?.easyNumbers.period || {}
  const revenueRounded = Math.round(revenue)
  const expenseRounded = Math.round(expense)
  const isProfit = revenueRounded - expenseRounded >= 0

  const chartData: BarChartData[] = useMemo(
    () => [
      {
        label: t('revenue'),
        value: Math.abs(revenueRounded),
        displayValue: revenueRounded,
        color: revenueRounded < 0 ? Color.Red : Color.Green,
        isBarHidden: revenueRounded < 0,
      },
      {
        label: t('expenses'),
        value: Math.abs(expenseRounded),
        color: Color.Red,
      },
      {
        label: isProfit ? t('profit') : t('loss'),
        value: Math.abs(revenueRounded - expenseRounded),
        color: isProfit ? Color.Green : Color.Red,
      },
    ],
    [revenueRounded, expenseRounded, t, isProfit],
  )

  const isBarChartVisible = useMemo(() => chartData.some((data) => data.value > 0), [chartData])
  const isEmptyStateVisible = !isLoading && !isBarChartVisible && !!organization

  return (
    <Widget title={t('dashboard.widget.overview.title')}>
      <Styled.ChartWrapper>
        {isEmptyStateVisible && <EmptyStateImage image={emptyImageByLanguage[localeCode]} />}
        {isBarChartVisible && <BarChart isLoading={isLoading} data={chartData} shortValueFormat />}
      </Styled.ChartWrapper>
    </Widget>
  )
}
