import { useCallback } from 'react'

import { groupWidgets } from '../elements/Widgets/elements/VisibleWidgets/elements/VisibleWidgetsList/utils/groupWidgets'
import { Widgets } from '../types/widgets'
import { useDefaultWidgets } from './useDefaultWidgets'
import { useUpdateOrganizationSettingsWidgets } from './useUpdateOrganizationSettingsWidgets'

export const useCompareWidgetsWithDefault = () => {
  const defaultWidgets = useDefaultWidgets()
  const { updateOrganizationSettingsWidgets } = useUpdateOrganizationSettingsWidgets()

  const compareWidgetsWithDefault = useCallback(
    (widgets: Widgets) => {
      const defaultIds = new Set([
        ...defaultWidgets.visible.map((defaultWidget) => defaultWidget.id),
        ...defaultWidgets.hidden.map((defaultWidget) => defaultWidget.id),
      ])

      const filteredVisible = widgets.visible.filter((widget) => defaultIds.has(widget.id))
      const filteredHidden = widgets.hidden.filter((widget) => defaultIds.has(widget.id))

      const allCurrentIds = new Set([
        ...filteredVisible.map((widget) => widget.id),
        ...filteredHidden.map((widget) => widget.id),
      ])

      const needsUpdate = allCurrentIds.size !== defaultIds.size

      const synced = {
        visible: [...filteredVisible],
        hidden: [...filteredHidden],
      }

      for (const widget of defaultWidgets.visible) {
        if (!allCurrentIds.has(widget.id)) {
          synced.visible.push(widget)
        }
      }

      for (const widget of defaultWidgets.hidden) {
        if (!allCurrentIds.has(widget.id)) {
          synced.hidden.push(widget)
        }
      }

      if (needsUpdate) {
        updateOrganizationSettingsWidgets({ widgets: synced })
      }

      return {
        hidden: groupWidgets(synced.hidden).flat(),
        visible: groupWidgets(synced.visible).flat(),
      }
    },
    [defaultWidgets, updateOrganizationSettingsWidgets],
  )

  return { compareWidgetsWithDefault }
}
