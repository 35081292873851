import { getInputStyle, getInputWrapperStyle } from '@design-system'

import styled from '@emotion/styled'

import { Themable } from '../../../../../types/themable'

export const AlgoliaWrapper = styled.div`
  width: 100%;
`

type AlgoliaInputSearchWrapperProps = Themable

export const AlgoliaInputSearchWrapper = styled.div<AlgoliaInputSearchWrapperProps>`
  form {
    ${({ theme }) =>
      getInputWrapperStyle({
        alignment: 'left',
        autoCompleted: false,
        bordered: true,
        disabled: false,
        error: false,
        focused: false,
        hidden: false,
        selectLook: false,
        size: 'xl',
        theme,
        truncate: false,
        weight: 'regular',
      })};
  }

  input {
    ${({ theme }) => getInputStyle(theme)};

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-corner,
    &::-webkit-search-cancel-button {
      display: none;
    }
  }

  .ais-SearchBox-reset,
  .ais-SearchBox-submit {
    display: flex;
    align-items: center;
    padding: 0;
    border: none;
  }

  // Submit requires custom spacing :(
  .ais-SearchBox-submit {
    margin-right: 10px;
    margin-left: 3px;
    order: -1;
    pointer-events: none;
  }

  // Reset requires custom spacing :(
  .ais-SearchBox-reset {
    margin-right: 3px;
    cursor: pointer;

    &[hidden] {
      display: none;
    }
  }
`
