import { Modal, ModalProps } from '@design-system'

import { ReactElement } from 'react'
import 'react-i18next'

import { useMissingContactDetailsForm } from '../../contexts/MissingContactDetailsFormContext'
import { SendAsEInvoiceModalBody } from './elements/SendAsEInvoiceModalBody'
import { SendAsEInvoiceModalFooter } from './elements/SendAsEInvoiceModalFooter'

type SendAsEInvoiceModalComponentProps = ModalProps

export const SendAsEInvoiceModalComponent = (modalProps: SendAsEInvoiceModalComponentProps): ReactElement => {
  const { isSubmitting } = useMissingContactDetailsForm()

  // TODO: add translations
  return (
    <Modal {...modalProps} size="s" closable={!isSubmitting}>
      <Modal.Header title="Send as e-invoice" />
      <SendAsEInvoiceModalBody />
      <SendAsEInvoiceModalFooter id={modalProps.id} />
    </Modal>
  )
}
