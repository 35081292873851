import { Widgets } from '../types/widgets'
import { widgetId } from './widgetId'

export const defaultWidgets: Widgets = {
  visible: [
    { id: widgetId.GetStarted },
    { id: widgetId.Invoices },
    { id: widgetId.BankIntegration },
    { id: widgetId.Bills },
  ],
  hidden: [
    { id: widgetId.Graph },
    { id: widgetId.CarouselUpsell },
    { id: widgetId.Overview },
    { id: widgetId.FindAccountant },
    { id: widgetId.Vat },
    { id: widgetId.TopCustomers },
    { id: widgetId.Tasks },
    { id: widgetId.TopVendors },
  ],
}

export const defaultWidgetsAfterDayFromRegistering: Widgets = {
  visible: [
    { id: widgetId.Graph },
    { id: widgetId.CarouselUpsell },
    { id: widgetId.GetStarted },
    { id: widgetId.Invoices },
    { id: widgetId.BankIntegration },
    { id: widgetId.Bills },
    { id: widgetId.FindAccountant },
    { id: widgetId.Vat },
    { id: widgetId.Tasks },
    { id: widgetId.TopVendors },
  ],
  hidden: [{ id: widgetId.Overview }, { id: widgetId.TopCustomers }],
}

export const defaultBrandAgerasWidgets: Widgets = {
  visible: [{ id: widgetId.Bills }, { id: widgetId.TopCustomers }, { id: widgetId.TopVendors }, { id: widgetId.Vat }],
  hidden: [{ id: widgetId.Overview }],
}
