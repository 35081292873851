import { Button, Space, Text } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useSegment, useUserflow } from '../../../../../../hooks'
import { USERFLOW_SETTINGS_GUIDE_ID } from '../../../../constants/userflowIds'
import { widgetId } from '../../../../constants/widgetId'
import { useWidgetsActions } from '../../../../hooks/useWidgetsActions'
import { GetStartedActionPoint } from '../../enums/getStartedActionPoint'
import * as Styled from '../../styles'

export const PreChecklistContent = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { start } = useUserflow()
  const { trackDashboardActionPerformed } = useWidgetsActions()

  const startUserflow = useCallback(() => {
    track('xxx - hamster - tile experiment button clicked', {
      button: 'complete profile now',
    })

    trackDashboardActionPerformed({ action: GetStartedActionPoint.CompleteProfileNow, widgetId: widgetId.GetStarted })
    start(USERFLOW_SETTINGS_GUIDE_ID)
  }, [start, track, trackDashboardActionPerformed])

  return (
    <Styled.WidgetContentWrapper>
      <Styled.Header>
        <Space size="s" />
        <Text variant="displayM">{t('dashboard.widget.get_started.title')}</Text>
        <Space size="l" />
        <Styled.DescriptionWrapper>
          <Text variant="large">{t('dashboard.widget.get_started.description_1')}</Text>
        </Styled.DescriptionWrapper>
      </Styled.Header>
      <Styled.Footer>
        <Button onClick={startUserflow} variant="secondary">
          {t('dashboard.widget.get_started.cta')}
        </Button>
      </Styled.Footer>
      <Styled.Illustration />
    </Styled.WidgetContentWrapper>
  )
}
