import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { BaseEventPayload, ElementDragType } from '@atlaskit/pragmatic-drag-and-drop/types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useDashboardSettings } from '../../../../../contexts/dashboardSettingsContext'
import { useApplyAllFiltersWidgets } from '../../../../../hooks/useApplyAllFiltersWidgets'
import { useWidgetsActions } from '../../../../../hooks/useWidgetsActions'
import { WidgetId } from '../../../../../types/widgetId'
import { reorderVisibleWidgets } from '../../VisibleWidgets/elements/VisibleWidgetsList/utils/reorderVisibleWidgets'
import { isValidWidgetId } from '../utils/isValidWidgetId'

interface UseDraggableArgs {
  id: WidgetId
  isEditMode: boolean
}

export const useDraggable = ({ id, isEditMode }: UseDraggableArgs) => {
  const [isDragging, setIsDragging] = useState(false)
  const [currentDraggedWidgetId, setCurrentDraggedWidgetId] = useState<WidgetId | null>(null)

  const widgetRef = useRef<HTMLDivElement | null>(null)
  const { widgets, setWidgets } = useDashboardSettings()
  const { trackDashboardConfigurationChange } = useWidgetsActions()
  const { applyAllFilters } = useApplyAllFiltersWidgets()

  const filteredWidgets = useMemo(() => {
    return applyAllFilters(widgets)
  }, [applyAllFilters, widgets])

  const handleDragEnter = useCallback(
    ({ source, location }: BaseEventPayload<ElementDragType>) => {
      if (source.data.id === id) {
        return
      }

      const sourceIndex = filteredWidgets.visible.findIndex((widget) => widget.id === source.data.id)
      const dropTargetData = location.current.dropTargets[0]?.data
      const destinationIndex = filteredWidgets.visible.findIndex((widget) => widget.id === dropTargetData?.id)

      if (sourceIndex === -1 || destinationIndex === -1 || sourceIndex === destinationIndex) {
        return
      }

      const reorderedVisibleWidgets = reorderVisibleWidgets({
        destinationId: filteredWidgets.visible[destinationIndex].id,
        sourceId: filteredWidgets.visible[sourceIndex].id,
        widgets: filteredWidgets.visible,
      })

      const restVisibleWidgets = widgets.visible.filter(
        (widget) => !filteredWidgets.visible.some((filteredWidget) => widget.id === filteredWidget.id),
      )

      setWidgets({ hidden: widgets.hidden, visible: [...reorderedVisibleWidgets, ...restVisibleWidgets] })
    },
    [filteredWidgets, id, setWidgets, widgets],
  )

  useEffect(() => {
    const element = widgetRef.current

    if (!element || !isEditMode) {
      return
    }

    return combine(
      draggable({
        element,
        getInitialData: () => ({ id }),
        onDragStart({ source }) {
          if (isValidWidgetId(source.data.id)) {
            setCurrentDraggedWidgetId(source.data.id)
          }
          setIsDragging(true)
        },
        onDrop({ source }) {
          const sourceIndex = filteredWidgets.visible.findIndex((widget) => widget.id === source.data.id)
          const movedWidgetIndex = filteredWidgets.visible.findIndex(
            (widget) => widget.id === filteredWidgets.visible[sourceIndex].id,
          )
          const movedWidgetId = filteredWidgets.visible[sourceIndex].id
          trackDashboardConfigurationChange({ position: movedWidgetIndex, widgetId: movedWidgetId })

          setIsDragging(false)
        },
      }),
      dropTargetForElements({
        element,
        getData: () => ({ id }),
        onDragEnter: handleDragEnter,
        onDragLeave({ source }) {
          setIsDragging(source.data.id === id)
        },
        onDrop() {
          setIsDragging(false)
        },
      }),
    )
  }, [id, isEditMode, widgets, setWidgets, handleDragEnter, trackDashboardConfigurationChange, filteredWidgets])

  return {
    widgetRef,
    isDragging,
    currentDraggedWidgetId,
  }
}
