import { Icon } from '@design-system'

import React, { ReactElement, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Configure, InstantSearch, SearchBox } from 'react-instantsearch-dom'
import { useClickAway } from 'react-use'

import { KeyboardKey } from '../../../../../enums/keyboardKey'
import { searchClient } from '../../../../../utils/algoliaSearchClient'
import { AlgoliaHits } from '../AlgoliaHits'
import * as Styled from './styles'

const minimumCharactersToShowHits = 3

export const AlgoliaSearch = (): ReactElement => {
  const { t } = useTranslation()
  const [areHitsVisible, setAreHitsVisible] = useState(false)
  const [isInputFilled, setIsInputFilled] = useState(false)
  const [focusedHitId, setFocusedHitId] = useState<string>('')
  const hitsRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleHitsVisibility = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    const valueLength = event.currentTarget.value.length
    const isQueryLongerThanMinimumCharsAmount = valueLength >= minimumCharactersToShowHits

    setAreHitsVisible(isQueryLongerThanMinimumCharsAmount)
    setFocusedHitId('')
    setIsInputFilled(valueLength !== 0)
  }, [])

  const getFocusedHitElement = useCallback((): HTMLElement | null => {
    return (focusedHitId && hitsRef?.current?.querySelector(`#${focusedHitId}`)) || null
  }, [focusedHitId])

  const getNextFocusedHitElement = useCallback(
    (hitsListElement: HTMLElement, key: KeyboardKey.ArrowDown | KeyboardKey.ArrowUp): Element | null => {
      const focusedHitElement = getFocusedHitElement()
      const firstHitElement = hitsListElement.firstElementChild
      const lastHitElement = hitsListElement.lastElementChild

      if (key === KeyboardKey.ArrowUp) {
        return focusedHitElement?.previousElementSibling || lastHitElement
      }

      return focusedHitElement?.nextElementSibling || firstHitElement
    },
    [getFocusedHitElement],
  )

  const handlePressArrow = useCallback(
    (key: KeyboardKey.ArrowDown | KeyboardKey.ArrowUp) => {
      const hitsListElement = hitsRef?.current?.querySelector('ul')
      if (!hitsListElement) {
        return
      }

      const nextFocusedHitElement = getNextFocusedHitElement(hitsListElement, key)

      // Set new focused item & scroll view to it
      if (nextFocusedHitElement?.id) {
        setFocusedHitId(nextFocusedHitElement.id)
        nextFocusedHitElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    },
    [getNextFocusedHitElement],
  )

  const handlePressEnter = useCallback(() => {
    const focusedHitElement = getFocusedHitElement()
    if (!focusedHitElement) {
      return
    }

    const hitLinkElement = focusedHitElement.querySelector('a')

    if (hitLinkElement) {
      // Trigger click event, so the insights event handler is also triggered
      hitLinkElement.click()
    }
  }, [getFocusedHitElement])

  // Adds dropdown keyboard controls
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (!areHitsVisible) {
        return
      }

      const { key: keyboardKey } = event

      if (keyboardKey === KeyboardKey.Enter) {
        event.preventDefault()
        handlePressEnter()
      } else if (keyboardKey === KeyboardKey.ArrowDown || keyboardKey === KeyboardKey.ArrowUp) {
        event.preventDefault()
        handlePressArrow(keyboardKey)
      }
    },
    [areHitsVisible, handlePressEnter, handlePressArrow],
  )

  // Set a proper search input placeholder
  useEffect(() => {
    inputRef.current?.setAttribute('placeholder', t('help.modal.form.search.placeholder'))
  }, [t])

  // Close hits container on click outside
  useClickAway(hitsRef, () => setAreHitsVisible(false))

  return (
    <InstantSearch indexName="website" searchClient={searchClient}>
      <Styled.AlgoliaWrapper>
        <Styled.AlgoliaInputSearchWrapper>
          <SearchBox
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            /* @ts-ignore */ // 'onFocus' prop type is missed but exists and works properly
            onFocus={handleHitsVisibility}
            onChange={handleHitsVisibility}
            onKeyDown={handleKeyDown}
            submit={<Icon icon="magnifyingGlass" />}
            reset={isInputFilled ? <Icon icon="xSign" /> : undefined}
            inputRef={inputRef}
            autoFocus
          />
        </Styled.AlgoliaInputSearchWrapper>
        {areHitsVisible && <AlgoliaHits forwardedRef={hitsRef} focusedHitId={focusedHitId} />}
      </Styled.AlgoliaWrapper>
      <Configure clickAnalytics />
    </InstantSearch>
  )
}
