import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { usePolling } from '../../../hooks/usePolling'
import { getBillReconciliationMatches } from '../query-api'
import { Bill } from '../types/bill'

const MAX_POLLING_ATTEMPTS = 2

export const useBillReconciliationSuggestions = (bill?: Bill) => {
  const enableSuggestionQuerying = useMemo(() => {
    return !!bill?.organizationId && !bill.isPaid
  }, [bill])
  const { data, isLoading, refetch } = useQuery(
    [QueryKeys.BillRecociliationMatches, bill?.id],
    () =>
      getBillReconciliationMatches({
        organizationId: bill?.organizationId as string,
        billId: bill?.id as string,
      }),
    {
      enabled: enableSuggestionQuerying,
      refetchOnMount: false,
      keepPreviousData: true,
      notifyOnChangeProps: ['data', 'isLoading'],
    },
  )

  const isBillApprovedToday = useMemo(() => {
    if (!bill?.approvedTime) {
      return false
    }
    const today = new Date().setHours(0, 0, 0, 0)
    return new Date(bill.approvedTime).setHours(0, 0, 0, 0) === today
  }, [bill?.approvedTime])
  usePolling({
    onPoll: refetch,
    isEnabled: data?.suggestions.length === 0 && isBillApprovedToday,
    maxAttempts: MAX_POLLING_ATTEMPTS,
  })

  const filteredSuggestions = useMemo(
    () =>
      data?.suggestions?.filter((suggestion) => {
        const transaction = suggestion.transactions[0]
        const reconcilable = suggestion.reconcilables[0]
        // filter out suggestions that has reconcilable with different currency than the transaction
        return reconcilable.currency === transaction.currency
      }),
    [data?.suggestions],
  )

  const billHasMatchSuggestion = useMemo(() => !!filteredSuggestions?.length, [filteredSuggestions?.length])

  return { suggestions: filteredSuggestions, isLoading, billHasMatchSuggestion }
}
