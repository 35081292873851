import React, { ReactElement, useMemo } from 'react'

import { calculatePaginationDotTranslateValue } from '../../utils/calculatePaginationDotTranslateValue'
import * as Styled from './styles'

interface PaginationDotsProps {
  count: number
  currentIndex: number
  onPaginationItemClick?: (index: number) => void
}

export const PaginationDots = ({ count, currentIndex, onPaginationItemClick }: PaginationDotsProps): ReactElement => {
  // Calculate value for translateX to center the active dot
  const translateValue = useMemo(() => calculatePaginationDotTranslateValue(currentIndex, count), [currentIndex, count])

  return (
    <Styled.PaginationWrapper>
      <Styled.PaginationTrack translateValue={translateValue}>
        {Array.from({ length: count }).map((_, i) => {
          const handleDotClick = () => onPaginationItemClick && onPaginationItemClick(i)

          return (
            <Styled.PaginationItem
              active={i === currentIndex}
              key={i}
              data-testid="carousel-pagination-item"
              onClick={handleDotClick}
            />
          )
        })}
      </Styled.PaginationTrack>
    </Styled.PaginationWrapper>
  )
}
