import { useMemo } from 'react'

import { useOrganizationBrand } from '../../../hooks'
import { useOnboarding } from '../../../hooks/useOnboarding'
import {
  defaultBrandAgerasWidgets,
  defaultWidgets,
  defaultWidgetsAfterDayFromRegistering,
} from '../constants/defaultWidgetsList'
import { groupWidgets } from '../elements/Widgets/elements/VisibleWidgets/elements/VisibleWidgetsList/utils/groupWidgets'

export const useDefaultWidgets = () => {
  const { isAfterDayFromRegistering } = useOnboarding()
  const { isBrandAgeras } = useOrganizationBrand()

  const widgets = useMemo(() => {
    if (isBrandAgeras) {
      return defaultBrandAgerasWidgets
    }

    if (isAfterDayFromRegistering) {
      return defaultWidgetsAfterDayFromRegistering
    }

    return defaultWidgets
  }, [isAfterDayFromRegistering, isBrandAgeras])

  return useMemo(() => {
    return {
      hidden: groupWidgets(widgets.hidden).flat(),
      visible: groupWidgets(widgets.visible).flat(),
    }
  }, [widgets])
}
