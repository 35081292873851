import React from 'react'
import { useTranslation } from 'react-i18next'

import { Direction } from '../../../../hooks/usePagination'
import { NavigationDirection } from '../../../../types/navigationDirection'
import { Button } from '../../../Button'
import { ButtonsGroup } from '../../../ButtonsGroup'
import { FileButton } from '../../../FileButton'
import { FloatingActionsMenuProps } from '../../types'
import { FloatingActionsPanel } from '../FloatingActionsPanel'
import * as Styled from './styles'

interface FileActionsProps {
  activePageNumber: number
  actionsMenuItems?: (props: FloatingActionsMenuProps) => React.ReactNode
  canChangePage: (direction: Direction) => boolean
  handleChangePage: (direction: Direction) => void
  handleDeleteClick: () => void
  handleDownloadClick: () => void
  handleRotateClick: () => void
  handleFileSelect: (files: File[]) => void
  isFloatingActionsView?: boolean
  isInView: boolean
  isLoading: boolean
  navigationDirection: NavigationDirection
  onImageZoomClick?: () => void
  pagesAmount: number
  shouldRenderNavigation: boolean
  withDeleteButton?: boolean
  withDownloadButton?: boolean
  withEditButton: boolean
  withModalZoom: boolean
  withPagination: boolean
  withRotate: boolean
  zoomable: boolean
}

export const FileActions = (props: FileActionsProps) => {
  const {
    handleDeleteClick,
    handleDownloadClick,
    handleFileSelect,
    handleRotateClick,
    isFloatingActionsView = false,
    onImageZoomClick,
    withDeleteButton,
    withDownloadButton,
    withEditButton,
    withModalZoom,
    withRotate,
  } = props
  const { t } = useTranslation()

  const ButtonsView = (
    <ButtonsGroup>
      {withRotate && (
        <Button
          icon="rightArrowAroundSquare"
          onClick={handleRotateClick}
          size="m"
          title={t('rotate')}
          variant="secondary"
        />
      )}
      {withDownloadButton && (
        <Button
          icon="arrowPointingDown"
          onClick={handleDownloadClick}
          size="m"
          title={t('download')}
          variant="secondary"
        />
      )}
      {withEditButton && (
        <FileButton
          icon="paperWithPencil"
          size="m"
          title={t('edit')}
          variant="secondary"
          onFilesSelect={handleFileSelect}
        />
      )}
      {withModalZoom && (
        <Button
          icon="magnifyingGlassPlus"
          onClick={onImageZoomClick}
          size="m"
          title={t('full_screen')}
          variant="secondary"
        />
      )}
      {withDeleteButton && (
        <Button icon="trash" onClick={handleDeleteClick} size="m" title={t('delete')} variant="secondary" />
      )}
    </ButtonsGroup>
  )

  return isFloatingActionsView ? (
    <FloatingActionsPanel {...props} />
  ) : (
    <Styled.ActionsWrapper>{ButtonsView}</Styled.ActionsWrapper>
  )
}
