import React, { forwardRef, ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface CarouselContainerProps {
  children: ReactNode
  currentIndex: number
  disableTransition?: boolean
  onTransitionEnd?: () => void
}

export const CarouselContainer = forwardRef<HTMLDivElement, CarouselContainerProps>(
  ({ children, currentIndex, disableTransition, onTransitionEnd }, ref): ReactElement => {
    return (
      <Styled.CarouselTrack
        ref={ref}
        currentIndex={currentIndex}
        disableTransition={disableTransition}
        onTransitionEnd={onTransitionEnd}
      >
        {React.Children.map(children, (child, index) => (
          <Styled.CarouselSlide key={index} aria-hidden={index !== currentIndex} data-testid="carousel-slide">
            {child}
          </Styled.CarouselSlide>
        ))}
      </Styled.CarouselTrack>
    )
  },
)
