import { ReactNode, useMemo } from 'react'

import { WidgetId } from '../../../../types/widgetId'
import { isFullWidthWidget } from '../../../../utils/isFullWidthWidget'
import { useDraggable } from './hooks/useDraggable'
import * as Styled from './styles'

interface DraggableWidgetProps {
  children: ReactNode
  id: WidgetId
  isEditMode: boolean
}

export const DraggableWidget = ({ children, id, isEditMode }: DraggableWidgetProps) => {
  const { widgetRef, currentDraggedWidgetId, isDragging } = useDraggable({ id, isEditMode })

  const isFullWidth = useMemo(() => isFullWidthWidget(id), [id])

  return (
    <Styled.DraggableWidgetContainer
      ref={widgetRef}
      isEditMode={isEditMode}
      isDragging={isDragging}
      isFullWidth={isFullWidth}
      isWidgetDragged={currentDraggedWidgetId === id}
      data-testid={`visible-widget-${id}`}
    >
      {children}
    </Styled.DraggableWidgetContainer>
  )
}
