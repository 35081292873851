import { Input } from '@design-system'

import { useFormContext } from '../../../../../../../../../../../../../../../../hooks'
import { MissingContactDetailsFormSchema } from '../../../../../../types/MissingContactDetailsFormSchema'

export const ContactAddress = () => {
  const { FormItem } = useFormContext<MissingContactDetailsFormSchema>()

  // TODO: add translations
  return (
    <FormItem
      name="contactAddress"
      label="Street Address"
      render={({ field: { onChange, ...rest } }) => {
        return (
          <Input
            {...rest}
            onChange={(contactAddress) => {
              onChange(contactAddress)
            }}
          />
        )
      }}
    />
  )
}
