import { useModalContentToggler } from '@components'
import { Link, Space, Text } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useBillReconciliation } from '@views/bills/elements/BillReconciliationModal/contexts/billReconciliationContext'

import { useSegment } from '../../../../../../../../hooks'
import { BillReconciliationModalStateId } from '../../../../enums/billReconciliationModalStateId'

export const LinkToManualPayment = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { initialBill } = useBillReconciliation()
  const { setActiveState } = useModalContentToggler()

  const handleLinkClick = useCallback(() => {
    setActiveState(BillReconciliationModalStateId.ManualPayment)

    track('xxx - reporting - bills - Match found - Enter payment without reconciling', {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      bill_id: initialBill?.id,
    })
  }, [setActiveState, track, initialBill?.id])

  return (
    <>
      <Space />
      <Text colorVariant="secondary">
        {t('bill.payment_reconcile.cant_find_bank_line')}{' '}
        <Text as="span">
          <Link onClick={handleLinkClick}>{t('bill.payment_reconcile.enter_without_reconciling')}</Link>
        </Text>
      </Text>
    </>
  )
}
