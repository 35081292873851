import { BrandProtectedComponent, ProtectedComponent } from '@components'
import { ModuleLayout, withModalConditionalRender } from '@design-system'

import { useTranslation } from 'react-i18next'

import { ExternalInvoicesSyncDashboardBanner } from '@features/externalInvoicesSync'
import { ConnectionRenewedModal } from '@views/bankIntegration/elements/SuccessfulConnectionModal/ConnectionRenewedModal'

import { ModalId } from '../../../../enums/modalId'
import { Scope } from '../../../../enums/scope'
import { useSynchronizeWidgets } from '../../hooks/useSynchronizeWidgets'
import { AnnualReports } from '../Banners/AnnualReports'
import { FinancingApplication } from '../Banners/FinancingApplication'
import { DashboardActions } from '../DashboardActions'
import { DashboardSkeleton } from '../DashboardSkeleton'
import { DashboardWidgetsEditAction } from '../DashboardWidgetsEditAction'
import { Widgets } from '../Widgets'

const BankConnectionRenewedModal = withModalConditionalRender(ConnectionRenewedModal)

export const DashboardLayout = () => {
  const { t } = useTranslation()

  const { isFetchingOrganizationSettings } = useSynchronizeWidgets()

  return (
    <ModuleLayout
      title={t('dashboard')}
      sideActions={<DashboardActions />}
      headerContent={
        <ProtectedComponent scopes={Scope.DashboardWrite}>
          <DashboardWidgetsEditAction />
        </ProtectedComponent>
      }
    >
      {isFetchingOrganizationSettings ? (
        <DashboardSkeleton />
      ) : (
        <>
          <FinancingApplication />
          <AnnualReports />
          <BrandProtectedComponent brand="ageras">
            <ExternalInvoicesSyncDashboardBanner />
          </BrandProtectedComponent>
          <Widgets />
          <BankConnectionRenewedModal id={ModalId.BankConnectionSuccessModal} />
        </>
      )}
    </ModuleLayout>
  )
}
