import { ModalProps, withModalConditionalRender } from '@design-system'

import { MissingContactDetailsFormContextProvider } from './contexts/MissingContactDetailsFormContext'
import { SendAsEInvoiceModalComponent } from './elements/SendAsEInvoiceModalComponent'

type SendAsEInvoiceProps = ModalProps

const SendAsEInvoice = (modalProps: SendAsEInvoiceProps) => {
  return (
    <MissingContactDetailsFormContextProvider>
      <SendAsEInvoiceModalComponent {...modalProps} />
    </MissingContactDetailsFormContextProvider>
  )
}

export const SendAsEInvoiceModal = withModalConditionalRender(SendAsEInvoice)
