import { useOrganizationBookkeepers } from '@views/settings/routes/OrganizationSettingsUsers/hooks/useOrganizationBookkeepers'

import { Scope } from '../../../enums/scope'
import { isAuthorized } from '../../../utils/isAuthorized'

export const useFindAccountantAvailability = () => {
  const isOrganizationBookkeepersAvailable = isAuthorized(Scope.FindAccountant)

  const { data, isLoading: areBookkeeepersLoading } = useOrganizationBookkeepers({
    enabled: isOrganizationBookkeepersAvailable,
    refetchOnWindowFocus: false,
  })

  const hasBookkeepers = data.length !== 0
  const isLoading = isOrganizationBookkeepersAvailable && areBookkeeepersLoading

  return {
    isFindAccountAvailable: isOrganizationBookkeepersAvailable && !isLoading && !hasBookkeepers,
  }
}
