import { ReactElement } from 'react'

import { WidgetId } from '../types/widgetId'
import { BankIntegration } from '../widgets/BankIntegration'
import { Bills } from '../widgets/Bills'
import { CarouselUpsell } from '../widgets/CarouselUpsell'
import { FindAccountant } from '../widgets/FindAccountant'
import { GetStarted } from '../widgets/GetStarted'
import { Graph } from '../widgets/Graph'
import { Invoices } from '../widgets/Invoices'
import { Overview } from '../widgets/Overview'
import { Tasks } from '../widgets/Tasks'
import { TopCustomers } from '../widgets/TopCustomers'
import { TopVendors } from '../widgets/TopVendors'
import { Vat } from '../widgets/Vat'

export const widgetToComponent: Record<WidgetId, ReactElement> = {
  BankIntegration: <BankIntegration />,
  Bills: <Bills />,
  CarouselUpsell: <CarouselUpsell />,
  FindAccountant: <FindAccountant />,
  GetStarted: <GetStarted />,
  Graph: <Graph />,
  Invoices: <Invoices />,
  Overview: <Overview />,
  Tasks: <Tasks />,
  TopCustomers: <TopCustomers />,
  TopVendors: <TopVendors />,
  Vat: <Vat />,
}
