import { ProtectedComponent, Translate } from '@components'
import {
  AmountValue,
  BadgeVariant,
  BadgeWithLabel,
  Button,
  Color,
  SkeletonBox,
  SkeletonText,
  Text,
} from '@design-system'

import { formatDistance } from 'date-fns'
import map from 'lodash/map'
import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { fetchInvoices, InvoicesQueryParams } from '@modules-deprecated/app/invoices'
import { useUserOrganization } from '@modules-deprecated/app/organization'

import { EmberRoute } from '../../../../enums/emberRoute'
import { QueryKeys } from '../../../../enums/queryKeys'
import { Scope } from '../../../../enums/scope'
import { useEmberRouteUrl } from '../../../../hooks/routing/useEmberRouteUrl'
import { useLocale } from '../../../../hooks/useLocale'
import { getQueryParamsString } from '../../../../utils/getQueryParamsString'
import { widgetId } from '../../constants/widgetId'
import { Widget } from '../../elements/Widget'
import { useFiscalYearQueryParams } from '../../hooks/useFiscalYearQueryParams'
import { useWidgetsActions } from '../../hooks/useWidgetsActions'
import { fetchSummary, StatsDataQueryParams } from '../../services/query-api'
import { invoiceStateToTranslationKey } from './constants/invoiceStateToTranslationKey'
import { OnboardingEmptyState } from './elements/OnboardingEmptyState'
import { InvoicesActionPoint } from './enums/invoicesActionPoint'
import { InvoiceItem } from './InvoiceItem'
import * as Styled from './styles'

const invoicesQueryParams: InvoicesQueryParams = {
  state: 'approved',
  pageSize: 1,
  offset: 0,
}

export type InvoiceState = 'overdue' | 'unpaid' | 'draft'

interface StatsProps {
  count: number
  amount: number
  badgeVariant: BadgeVariant
}

type Stats = Record<InvoiceState, StatsProps>

export const Invoices = (): ReactElement => {
  const { t } = useTranslation()
  const history = useHistory()
  const { organization } = useUserOrganization()
  const { queryParams: fiscalYearQueryParams } = useFiscalYearQueryParams(organization?.id)
  const locale = useLocale()
  const { url: invoicesUrl } = useEmberRouteUrl(EmberRoute.Invoices)
  const { url: newInvoiceUrl } = useEmberRouteUrl(EmberRoute.InvoicesNew)
  const { trackDashboardActionPerformed } = useWidgetsActions()

  const {
    data: summaryData,
    isLoading: isSummaryDataLoading,
    dataUpdatedAt,
  } = useQuery(
    [QueryKeys.InvoicesSummary, organization?.id, fiscalYearQueryParams],
    () => fetchSummary(organization?.id || '', fiscalYearQueryParams || ({} as StatsDataQueryParams)),
    {
      enabled: !!organization,
      refetchOnMount: 'always',
    },
  )
  const { data: invoicesData, isLoading: isInvoicesDataLoading } = useQuery(
    [QueryKeys.Invoices, organization?.id, invoicesQueryParams],
    () => fetchInvoices(organization?.id || '', invoicesQueryParams),
    {
      enabled: !!organization,
      refetchOnMount: 'always',
    },
  )

  const { summary } = summaryData || {}
  const isLoading = isSummaryDataLoading || isInvoicesDataLoading || !organization
  const lastUpdateDistance = formatDistance(dataUpdatedAt, new Date(), {
    addSuffix: true,
    locale,
  })

  const stats: Stats = useMemo(
    () => ({
      overdue: {
        count: summary?.overdue.count || 0,
        amount: summary?.overdue.amount || 0,
        badgeVariant: 'error',
      },
      unpaid: {
        count: summary?.unpaid.count || 0,
        amount: summary?.unpaid.amount || 0,
        badgeVariant: 'warning',
      },
      draft: {
        count: summary?.draft.count || 0,
        amount: summary?.draft.amount || 0,
        badgeVariant: 'primary',
      },
    }),
    [summary],
  )

  const doInvoicesExist = invoicesData && invoicesData.meta.paging.total > 0
  const shouldShowEmptyState = !isLoading && !doInvoicesExist && !!organization

  const getCurrentAction = (invoiceState: InvoiceState) => {
    switch (invoiceState) {
      case 'draft':
        return InvoicesActionPoint.Draft
      case 'overdue':
        return InvoicesActionPoint.Overdue
      case 'unpaid':
        return InvoicesActionPoint.Unpaid
    }
  }

  const handleValueClick = (invoiceState: InvoiceState) => {
    const queryParams = { status: invoiceState }
    const queryParamsString = getQueryParamsString(queryParams)
    history.push(`${invoicesUrl}?${queryParamsString}`)
    trackDashboardActionPerformed({ action: getCurrentAction(invoiceState), widgetId: widgetId.Invoices })
  }

  const handleCreateInvoiceClick = () => {
    trackDashboardActionPerformed({ action: InvoicesActionPoint.CreateInvoice, widgetId: widgetId.Invoices })

    if (newInvoiceUrl) {
      history.push(newInvoiceUrl)
    }
  }

  return (
    <Widget
      title={!shouldShowEmptyState ? t('dashboard.widget.invoices.title') : undefined}
      sideActions={
        doInvoicesExist && (
          <ProtectedComponent scopes={Scope.InvoiceWrite}>
            <Button variant="secondary" onClick={handleCreateInvoiceClick}>
              <Translate value="dashboard.widget.invoices.create" />
            </Button>
          </ProtectedComponent>
        )
      }
      backgroundColor={shouldShowEmptyState ? Color.ElectricGreen : Color.White}
    >
      {isLoading ? (
        <SkeletonBox fullWidth height={150} />
      ) : (
        <>
          {shouldShowEmptyState && <OnboardingEmptyState />}
          {doInvoicesExist && (
            <>
              <Styled.InvoicesList>
                {map(stats, (stat: StatsProps, key: InvoiceState) => {
                  const { badgeVariant, count, amount } = stat
                  const invoiceStateTranslationKey = invoiceStateToTranslationKey[key]

                  return (
                    <InvoiceItem invoiceState={key} key={key} onClick={handleValueClick}>
                      <Styled.BadgeWrapper>
                        <Styled.BadgeWrapper>
                          <BadgeWithLabel variant={badgeVariant} badge={count}>
                            {t(invoiceStateTranslationKey)}
                          </BadgeWithLabel>
                        </Styled.BadgeWrapper>
                      </Styled.BadgeWrapper>
                      {isLoading ? (
                        <SkeletonText variant="h1" width={110} />
                      ) : (
                        <AmountValue
                          amount={amount}
                          currencyId={organization?.baseCurrencyId}
                          textVariant="h1"
                          shortFormat
                        />
                      )}
                    </InvoiceItem>
                  )
                })}
              </Styled.InvoicesList>

              <Styled.Footer>
                <Text colorVariant="secondary" variant="micro">
                  <Translate value="dashboard.widget.invoices.last_updated" /> {lastUpdateDistance}
                </Text>
              </Styled.Footer>
            </>
          )}
        </>
      )}
    </Widget>
  )
}
