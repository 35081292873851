import React, { useCallback, useMemo } from 'react'

import { widgetToComponent } from '../../../../../../constants/widgetIdToComponent'
import { useDashboardSettings } from '../../../../../../contexts/dashboardSettingsContext'
import { useApplyAllFiltersWidgets } from '../../../../../../hooks/useApplyAllFiltersWidgets'
import { useWidgetsActions } from '../../../../../../hooks/useWidgetsActions'
import { WidgetId } from '../../../../../../types/widgetId'
import { ToggleVisibilityWidgetButton } from '../../../../../ToggleVisibilityWidgetButton'
import { DraggableWidget } from '../../../DraggableWidget'

export const VisibleWidgetsList = () => {
  const { widgets, isEditMode } = useDashboardSettings()
  const { hideWidget } = useWidgetsActions()
  const { applyAllFilters } = useApplyAllFiltersWidgets()

  const handleHideWidgetClick = useCallback(
    (id: WidgetId) => {
      hideWidget(id)
    },
    [hideWidget],
  )

  const filteredWidgets = useMemo(() => {
    return applyAllFilters(widgets)
  }, [applyAllFilters, widgets])

  if (widgets.visible.length === 0) {
    return
  }

  return filteredWidgets.visible.map(({ id }) => (
    <DraggableWidget key={id} id={id} isEditMode={isEditMode}>
      <ToggleVisibilityWidgetButton handleClick={() => handleHideWidgetClick(id)} />
      {widgetToComponent[id]}
    </DraggableWidget>
  ))
}
