import { Button } from '@design-system'

import React from 'react'

import * as Styled from './styles'

interface ToggleVisibilityWidgetButtonProps {
  handleClick: () => void
}

export const ToggleVisibilityWidgetButton = ({ handleClick }: ToggleVisibilityWidgetButtonProps) => {
  return (
    <Styled.ToggleVisibilityWidgetButtonContainer>
      <Button
        icon="eyeCrossed"
        onClick={handleClick}
        size="m"
        variant="secondary"
        data-testid="toggle-visibility-widget-button"
      />
    </Styled.ToggleVisibilityWidgetButtonContainer>
  )
}
