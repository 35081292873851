import { Modal } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../enums/modalId'
import { HelpModalContent } from './elements/HelpModalContent'
import { HelpModalFooter } from './elements/HelpModalFooter'

export const HelpModal = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Modal closable id={ModalId.HelpModal} size="s">
      <Modal.Header title={t('help.modal.title')} />
      <Modal.Body>
        <HelpModalContent />
      </Modal.Body>
      <Modal.Footer>
        <HelpModalFooter />
      </Modal.Footer>
    </Modal>
  )
}
