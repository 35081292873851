import { NotesPanel, ProtectedComponent } from '@components'
import {
  Button,
  ButtonDropdown,
  ButtonsGroup,
  ButtonVariant,
  ModalConfirmation,
  NavItem,
  notify,
  SkeletonButton,
  SkeletonIconButton,
  Space,
  Tooltip,
  useModal,
  withModalConditionalRender,
} from '@design-system'

import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Link, useLocation } from 'react-router-dom'

import { useUserOrganization } from '@modules-deprecated/app/organization/userOrganizationContext'
import { useCurrentUser } from '@modules-deprecated/app/user/currentUserContext'
import { getPaymentRegistrationModalView } from '@views/bills/utils/getPaymentRegistrationModalView'
import { OrganizationFeature, useOrganizationFeature } from '@views/settings/routes/OrganizationFeaturesSettings'

import { OPEN_NOTES_PANEL_SHORTCUT } from '../../../../constants/shortcuts'
import { CustomEvent } from '../../../../enums/customEvent'
import { ModalId } from '../../../../enums/modalId'
import { QueryKeys } from '../../../../enums/queryKeys'
import { Scope } from '../../../../enums/scope'
import { useSegment } from '../../../../hooks'
import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { useCustomEventListener } from '../../../../hooks/useCustomEventListener'
import { APIError } from '../../../../utils'
import { getErrorMessage } from '../../../../utils/getErrorMessage'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { useBillsEmberConnection } from '../../contexts/billsEmberConnectionContext'
import { BillState } from '../../enums/billState'
import { useBillReconciliationSuggestions } from '../../hooks/useBillReconciliationSuggestions'
import { useBillTimeline } from '../../hooks/useBillTimeline'
import { useUpdateBill } from '../BillEditableForm/hooks/useUpdateBill'
import { BillNotesPanel } from '../BillNotesPanel'
import { useBillView } from '../BillReadableForm'
import { getBillTransactionsQueries } from '../BillReadableForm/elements/BillTransactions'
import { BillReconciliationModal as BillReconciliationModalComponent } from '../BillReconciliationModal'
import { useContactBalance } from '../BillReconciliationModal/contexts/contactBalanceContext'
import { BillVoidConfirmationMessage } from './elements/BillVoidConfirmationMessage'
import { useCloseReconciliationModals } from './hooks/useCloseReconcilliationModals'

const VOID_CONFIRMATION_MODAL_ID = 'void-bill-confirmation'

enum ReadableBillActionId {
  Duplicate = 'duplicate',
  Credit = 'credit',
  Void = 'void',
}

const actionToSegmentDescription: Record<ReadableBillActionId, string> = {
  [ReadableBillActionId.Duplicate]: 'duplicate',
  [ReadableBillActionId.Credit]: 'credit bill',
  [ReadableBillActionId.Void]: 'void bill',
}

const BillReconciliationModal = withModalConditionalRender(BillReconciliationModalComponent)

export const BillReadableActions = (): ReactElement => {
  const [isNotesPanelOpen, setIsNotesPanelOpen] = useState(false)
  const { t } = useTranslation()
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const { isLoading: isLoadingUserData } = useCurrentUser()
  const { registerPayment } = useBillsEmberConnection()
  const { billId, billData, isFetching, isVoided } = useBillView()
  const { hasFeature } = useOrganizationFeature()
  const hasBillReconciliationFeature = hasFeature(OrganizationFeature.BillReconciliation)

  const queryClient = useQueryClient()
  const location = useLocation()
  const { open: openVoidConfirmationModal, close: closeVoidConfirmationModal } = useModal(VOID_CONFIRMATION_MODAL_ID)
  const bill = useMemo(() => billData?.bill, [billData?.bill])
  const { isLoading: isLoadingMatchSuggestions, billHasMatchSuggestion } = useBillReconciliationSuggestions(bill)
  const { canUsePrepayment } = useContactBalance()
  const initialBillReconciliationModalState = useMemo(
    () => getPaymentRegistrationModalView(canUsePrepayment, billHasMatchSuggestion),
    [billHasMatchSuggestion, canUsePrepayment],
  )
  const { open: openBillReconciliationModal } = useModal(ModalId.BillReconciliationModal, {
    onOpen: () => {
      track('xxx - reporting - bills - Pay and reconcile modal opened', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        bill_id: billId,
      })
    },
  })
  const { count: notesCount } = useBillTimeline(billId)

  const { creditedBillId } = bill || {}
  const isLoading = isFetching || isLoadingUserData
  const isBillApprovedAndNotPaid = bill?.state === BillState.Approved && !bill?.isPaid
  const shouldRenderMoreButton = isLoading || (!isLoading && !isVoided)
  const shouldRenderRegisterPaymentButton = isLoading || (!isLoading && isBillApprovedAndNotPaid)
  const registerPaymentButtonVariant: ButtonVariant = billHasMatchSuggestion ? 'secondary' : 'primary'
  useCloseReconciliationModals()

  const { update: updateBill, isLoading: isBillUpdating } = useUpdateBill({
    onSuccess: () => {
      track('Bill Voided (Client)')

      const { queryKey: billTransactionsQueryKey } = getBillTransactionsQueries(billId)
      queryClient.setQueryData([QueryKeys.Bill, billId], {
        billData,
        bill: { ...(billData?.bill || {}), state: BillState.Voided },
      })

      if (creditedBillId) {
        queryClient.invalidateQueries([QueryKeys.Bill, creditedBillId])
      }
      queryClient.invalidateQueries([QueryKeys.Bill, billId])
      queryClient.invalidateQueries(billTransactionsQueryKey)
      closeVoidConfirmationModal()
    },
    onError: (error: APIError | undefined) => {
      const message = getErrorMessage(error, 'bill') || 'Could not void the bill'
      notify({ id: 'void-bill-error', message, variant: 'error' })
    },
  })

  // Handle ember's Register Payment success state
  useCustomEventListener(CustomEvent.PaymentSaved, () => {
    queryClient.invalidateQueries([QueryKeys.Bill, billId])
  })

  const { url: duplicateUrl } = useOrganizationViewUrl(reactRoute.bills.getDuplicateRoute(billId))
  const { url: createCreditNoteUrl } = useOrganizationViewUrl(reactRoute.bills.getCreateCreditNoteRoute(billId))

  const moreButtonItems: NavItem<ReadableBillActionId>[] = useMemo(
    () => [
      {
        id: ReadableBillActionId.Duplicate,
        children: t('bill.read.more.duplicate'),
        to: `${duplicateUrl}${location.search}`,
        value: ReadableBillActionId.Duplicate,
      },
      {
        id: ReadableBillActionId.Credit,
        children: t('bill.read.more.credit'),
        to: `${createCreditNoteUrl}${location.search}`,
        value: ReadableBillActionId.Credit,
      },
      {
        id: ReadableBillActionId.Void,
        children: t('bill.read.more.void'),
        value: ReadableBillActionId.Void,
      },
    ],
    [createCreditNoteUrl, duplicateUrl, location.search, t],
  )

  const handleRegisterPaymentButtonClick = useCallback(() => {
    const isCurrencyDifferentThanDanish = bill?.currencyId !== 'DKK'
    track('xxx - expenses - Register payment clicked')
    // eslint-disable-next-line @typescript-eslint/naming-convention
    track('xxx - reporting - bills - Register payment clicked', { bill_id: billId })

    if (isCurrencyDifferentThanDanish || !hasBillReconciliationFeature) {
      registerPayment({ billId })
      return
    }

    openBillReconciliationModal()
  }, [bill?.currencyId, hasBillReconciliationFeature, track, openBillReconciliationModal, registerPayment, billId])

  const handleMoreSelect = useCallback(
    (_: string, value: ReadableBillActionId) => {
      track('XXX - Jesper - Action Taken', {
        context: 'single_bill_view',
        action: `more: ${actionToSegmentDescription[value]}`,
      })

      if (value === ReadableBillActionId.Void) {
        openVoidConfirmationModal()
      }
    },
    [openVoidConfirmationModal, track],
  )

  const handleVoidModalOkClick = useCallback(() => {
    if (!organization?.id) {
      return
    }

    updateBill({ billId, payload: { state: BillState.Voided, organizationId: organization.id } })
    // eslint-disable-next-line @typescript-eslint/naming-convention
    track('Bill Payment Voided (Client)', { bill_id: billId })
  }, [billId, organization?.id, updateBill, track])

  const handleAddNotesButtonClick = useCallback(() => {
    setIsNotesPanelOpen(true)
  }, [])

  const handleNotesPanelClose = useCallback(() => {
    setIsNotesPanelOpen(false)
  }, [])

  const handleOpenNotesPanelShortcut = useCallback(() => {
    setIsNotesPanelOpen(true)
  }, [])

  const handleEditLinkClick = useCallback(() => {
    track('XXX - Jesper - Action Taken', {
      context: 'single_bill_view',
      action: 'more: edit',
    })
  }, [track])

  useHotkeys(
    OPEN_NOTES_PANEL_SHORTCUT,
    handleOpenNotesPanelShortcut,
    { enabled: !isLoading, enableOnTags: ['INPUT', 'SELECT', 'TEXTAREA'] },
    [isLoading, handleOpenNotesPanelShortcut],
  )

  const { url: editBillUrl } = useOrganizationViewUrl(reactRoute.bills.getEditBillRoute(billId))

  return (
    <>
      <ButtonsGroup>
        {shouldRenderRegisterPaymentButton &&
          (isLoading ? (
            <SkeletonButton width={172} />
          ) : (
            <Button
              icon="moneyBag"
              onClick={handleRegisterPaymentButtonClick}
              disabled={isLoadingMatchSuggestions}
              variant={registerPaymentButtonVariant}
            >
              {t('bill.register_payment.button.register')}
            </Button>
          ))}
        {!isVoided && (
          <ProtectedComponent scopes={Scope.BillWrite}>
            <Space size="m" horizontal />
            <Link
              onClick={handleEditLinkClick}
              to={{
                pathname: editBillUrl,
                search: location.search,
              }}
            >
              <Button icon="paperWithPencil" variant="secondary" disabled={isFetching}>
                {t('bill.view.edit_bill')}
              </Button>
            </Link>
          </ProtectedComponent>
        )}
        {isLoading ? (
          <SkeletonIconButton />
        ) : (
          <Tooltip label={t('note_panel.title')} placement="bottom">
            <NotesPanel.Trigger onClick={handleAddNotesButtonClick} count={notesCount} />
          </Tooltip>
        )}
        {shouldRenderMoreButton &&
          (isLoading ? (
            <SkeletonButton width={92} />
          ) : (
            <ButtonDropdown items={moreButtonItems} onSelect={handleMoreSelect} variant="secondary">
              {t('more')}
            </ButtonDropdown>
          ))}
      </ButtonsGroup>

      {/* Bill Reconciliation Modal */}
      <BillReconciliationModal
        bill={bill}
        id={ModalId.BillReconciliationModal}
        initialState={initialBillReconciliationModalState}
      />

      {/* Void Confirmation Modal  */}
      <ModalConfirmation
        cancelLabel={t('no')}
        danger
        id={VOID_CONFIRMATION_MODAL_ID}
        message={<BillVoidConfirmationMessage balanceModifiers={bill?.balanceModifiers} />}
        okLabel={t('bill.void_modal.ok')}
        okLoading={isBillUpdating}
        onOk={handleVoidModalOkClick}
        title={t('bill.void_modal.title')}
      />

      {/* Notes Panel */}
      <BillNotesPanel billId={billId} isOpen={isNotesPanelOpen} onClose={handleNotesPanelClose} />
    </>
  )
}
