import { useCallback } from 'react'

import { useIsBasicPlan } from '../../../hooks/useIsBasicPlan'
import { widgetId } from '../constants/widgetId'
import { Widget } from '../types/widget'
import { useFindAccountantAvailability } from './useFindAccountantAvailability'

export const useFilterWidgetsByBusinessRules = () => {
  const { isFindAccountAvailable } = useFindAccountantAvailability()
  const { isBasicPlan } = useIsBasicPlan()

  const filterWidgetsByBusinessRules = useCallback(
    <T extends Widget>(widgets: T[]): T[] => {
      return widgets.filter(
        ({ id }) =>
          ![
            [widgetId.FindAccountant, !isFindAccountAvailable],
            [widgetId.BankIntegration, isBasicPlan],
          ].some(([widgetId, condition]) => id === widgetId && condition),
      )
    },
    [isFindAccountAvailable, isBasicPlan],
  )

  return { filterWidgetsByBusinessRules }
}
