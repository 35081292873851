import styled from '@emotion/styled'

interface HtmlIframeProps {
  height?: number
  isLoading?: boolean
}

export const HtmlIframe = styled.iframe<HtmlIframeProps>`
  width: 210mm;
  height: ${({ height }) => (typeof height === 'number' ? `${height}px` : '100%')};
  pointer-events: none;

  ${({ isLoading }) => {
    if (isLoading) {
      return `
        visibility: hidden;
      `
    }
  }};
`
