import qs from 'qs'
import { ReactElement, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { BankIntegrationContextProvider } from '@views/bankIntegration/contexts/bankIntegrationContext'
import { AgerasFinanceCustomerContextProvider } from '@views/financing'

import { EmberRoute } from '../../enums/emberRoute'
import { useSegment } from '../../hooks'
import { useEmberRouteUrl } from '../../hooks/routing/useEmberRouteUrl'
import { BankWidgetContextProvider } from './contexts/bankWidgetContext'
import { DashboardSettingsContextProvider } from './contexts/dashboardSettingsContext'
import { DashboardLayout } from './elements/DashboardLayout'

export const Dashboard = (): ReactElement => {
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const location = useLocation()
  const { url: integrationsUrl } = useEmberRouteUrl(EmberRoute.Integrations)

  const searchParsed = qs.parse(location?.search || '', { ignoreQueryPrefix: true }) || {}
  const mobilePayQuery = searchParsed.mobilepaystatus

  useEffect(() => {
    if (!mobilePayQuery) {
      track('Dashboard Viewed (Client)')
    }
  }, [mobilePayQuery, track])

  if (mobilePayQuery && integrationsUrl) {
    return <Redirect to={integrationsUrl + location.search} />
  }

  const organizationId = organization?.id || ''

  return (
    <DashboardSettingsContextProvider>
      <BankIntegrationContextProvider organizationId={organizationId}>
        <BankWidgetContextProvider organizationId={organizationId}>
          <AgerasFinanceCustomerContextProvider organizationId={organizationId}>
            <DashboardLayout />
          </AgerasFinanceCustomerContextProvider>
        </BankWidgetContextProvider>
      </BankIntegrationContextProvider>
    </DashboardSettingsContextProvider>
  )
}
