import { WidgetId } from '../../../../../../../types/widgetId'
import { Widgets } from '../../../../../../../types/widgets'
import { isFullWidthWidget } from '../../../../../../../utils/isFullWidthWidget'
import { reorderFullWidthGroups } from './reorderFullWidthGroups'
import { reorderHalfWidthGroups } from './reorderHalfWidthGroups'

interface ReorderVisibleWidgetsArgs {
  destinationId: WidgetId
  sourceId: WidgetId
  widgets: Widgets['visible']
}

export const reorderVisibleWidgets = ({ destinationId, sourceId, widgets }: ReorderVisibleWidgetsArgs) => {
  const destinationIndex = widgets.findIndex((item) => item.id === destinationId)
  const sourceIndex = widgets.findIndex((item) => item.id === sourceId)
  const isInvalidOrder = sourceIndex === -1 || destinationIndex === -1 || sourceIndex === destinationIndex

  if (isInvalidOrder) {
    return [...widgets]
  }

  const isFullWidthWidgetDragged =
    isFullWidthWidget(widgets[sourceIndex].id) || isFullWidthWidget(widgets[destinationIndex].id)

  if (isFullWidthWidgetDragged) {
    return reorderFullWidthGroups({
      destinationIndex,
      sourceIndex,
      widgets,
    })
  }

  return reorderHalfWidthGroups({
    destinationIndex,
    sourceIndex,
    widgets,
  })
}
