import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder'

import { WidgetId } from '../../../../../../../types/widgetId'
import { ReorderWidthGroupsArgs } from '../types/reorderWidthGroupsArgs'
import { groupWidgets } from './groupWidgets'

export const reorderFullWidthGroups = ({ destinationIndex, sourceIndex, widgets }: ReorderWidthGroupsArgs) => {
  const grouped = groupWidgets(widgets)
  const sourceWidget = widgets[sourceIndex]
  const destinationWidget = widgets[destinationIndex]

  const findGroupIndex = (widgetId: WidgetId) =>
    grouped.findIndex((group) => group.some((item) => item.id === widgetId))

  const groupSourceIndex = findGroupIndex(sourceWidget.id)
  const groupDestinationIndex = findGroupIndex(destinationWidget.id)

  const reorderedGroups = reorder({
    list: grouped,
    startIndex: groupSourceIndex,
    finishIndex: groupDestinationIndex,
  })

  return reorderedGroups.flat()
}
