import { Text } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Account, AccountNature } from '@modules-deprecated/app/accounts/types'
import { useUserOrganization, useUserOrganizationSettings } from '@modules-deprecated/app/organization'
import { fetchAccountBalance } from '@modules-deprecated/bankReconciliation/query-api'
import { useBankIntegration } from '@views/bankIntegration/contexts/bankIntegrationContext'
import { useBankWidget } from '@views/dashboard/contexts/bankWidgetContext'
import { useMissingReceiptsBankLines } from '@views/thingsToDo/hooks/useMissingReceiptsBankLines'

import { QueryKeys } from '../../../../enums/queryKeys'
import { useBankAccounts } from '../../../../hooks/useBankAccounts'
import { Widget } from '../../elements/Widget'
import { BankConnection } from './elements/bankConnection/BankConnection'
import { BankDisconnection } from './elements/bankDisconnection/BankDisconnection'
import { BankSelection } from './elements/BankSelection'
import { ConnectionCTA } from './elements/connectionCTA/ConnectionCTA'
import * as Styled from './styles'
import { findWidgetBankConnection } from './utils/findWidgetBankConnection'
import { getAccountData } from './utils/getAccountData'
import { getActiveBankAccounts } from './utils/getActiveBankAccounts'

const DEFAULT_CURRENCY_ID = 'DKK'
const TODAYS_DATE = new Date()

export const BankIntegration = (): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { organizationSettings } = useUserOrganizationSettings()
  const { bankAccounts: allAccounts = [], isLoading: isAllAccountsLoading } = useBankAccounts()
  const { selectedBankAccountId } = useBankWidget()
  const { accountCurrency, accountId, bankConnections, isLoading: isLoadingBankIntegration } = useBankIntegration()

  const isInboxEnabled = organizationSettings?.isInboxEnabled === '1'

  const {
    bankLines: missingReceiptsBankLines,
    isLoading: isLoadingReceipts,
    isError: isMissingReceiptsError,
  } = useMissingReceiptsBankLines(isInboxEnabled)

  const bankConnection = useMemo(
    () => findWidgetBankConnection({ bankConnections, selectedBankAccountId }) || undefined,
    [bankConnections, selectedBankAccountId],
  )

  const { accountName, balance, connection, currency, externalAccountId, id, type } =
    getAccountData(bankConnection) || {}

  const activeBankAccounts: Account[] = useMemo(
    () => getActiveBankAccounts(allAccounts, bankConnections),
    [allAccounts, bankConnections],
  )

  const {
    data: accountBalance,
    isLoading: isAccountBalanceLoading,
    isError: isAccountBalanceError,
  } = useQuery(
    [QueryKeys.AccountBalance, selectedBankAccountId, currency],
    () => fetchAccountBalance(selectedBankAccountId as string, currency || DEFAULT_CURRENCY_ID, TODAYS_DATE),
    {
      enabled: !!selectedBankAccountId && !!organizationSettings,
    },
  )

  const isError = isAccountBalanceError || (isMissingReceiptsError && isInboxEnabled) || !!connection?.requiresUpdate
  const isLoading =
    isAllAccountsLoading ||
    isAccountBalanceLoading ||
    isLoadingBankIntegration ||
    isLoadingReceipts ||
    !organization ||
    !organizationSettings
  const isBankConnected = !!bankConnection

  const isMissingReceipts = missingReceiptsBankLines?.length > 0

  const shouldShowBankConnection = isBankConnected || isLoading || isError

  const getAccountBalance = () => {
    if (!accountBalance) {
      return undefined
    }

    const accountBalanceAmount = accountBalance.balance

    if (type === AccountNature.Liability && accountBalanceAmount !== 0) {
      // AccountNature.Liability requires the balance to be converted to the opposite. So positive -> negative and vice versas
      return accountBalanceAmount < 0 ? Math.abs(accountBalanceAmount) : -Math.abs(accountBalanceAmount)
    }

    return accountBalanceAmount
  }

  return (
    <Widget
      title={shouldShowBankConnection ? t('dashboard.widget.bank-integration.title') : undefined}
      sideActions={
        !isLoading &&
        (!!externalAccountId || isError) && (
          <ConnectionCTA accountId={externalAccountId} bankAccountId={id} isError={isError} />
        )
      }
    >
      {shouldShowBankConnection ? (
        <>
          <BankConnection
            accountBalanceAmount={getAccountBalance()}
            accountCurrencyId={currency || accountCurrency}
            bankBalanceAmount={balance}
            isError={isError}
            isLoading={isLoading}
          />

          {!isLoading && (
            <Styled.Footer>
              <Text weight="regular" variant="micro" colorVariant="secondary">
                {!isError && !isMissingReceipts ? accountName : ''}
              </Text>
              <BankSelection accountId={externalAccountId as string} bankAccounts={activeBankAccounts} />
            </Styled.Footer>
          )}
        </>
      ) : (
        <BankDisconnection accountId={accountId} />
      )}
    </Widget>
  )
}
