import { useMemo } from 'react'

import { WidgetId } from '../types/widgetId'

export type PermanentlyHiddenWidgetsMap = Partial<Record<WidgetId, boolean>>

export const usePermanentlyHiddenWidgets = (): PermanentlyHiddenWidgetsMap => {
  return useMemo(() => {
    return {}
  }, [])
}
