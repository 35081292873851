import { OrganizationSettings } from '@modules-deprecated/app/organization/types/organizationSettings'

import { widgetId } from '../constants/widgetId'
import { WidgetSize } from '../enums/widgetSize'
import { WidgetLegacy } from '../types/widget'
import { Widgets } from '../types/widgets'
import { isDeprecatedWidgetId } from './isDeprecatedWidgetId'
import { parseWidgetsList } from './parseWidgetsList'

type DashboardLayoutData = Required<OrganizationSettings>

export const modifyDashboardLayoutDataStructure = (dashboardLayoutData: DashboardLayoutData['dashboardLayout']) => {
  const parsedDashboardLayoutData: WidgetLegacy[] = parseWidgetsList<WidgetLegacy[]>(dashboardLayoutData, [])
  const sortedWidgets = parsedDashboardLayoutData.sort((a, b) => a.order - b.order)

  return sortedWidgets.reduce<Widgets>(
    (result, widget) => {
      const isExcluded = widget.size === WidgetSize.FullWidth && widget.id !== 'OverviewMonthly'

      if (isExcluded || widget.isStatic) {
        return result
      }

      const targetList = widget.isHidden ? result.hidden : result.visible

      if (widget.id === 'OverviewMonthly') {
        targetList.push({ id: widgetId.Graph })
      } else if (!isDeprecatedWidgetId(widget.id)) {
        targetList.push({ id: widget.id })
      }

      return result
    },
    { visible: [], hidden: [] },
  )
}
