import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { CarouselContainer } from './elements/CarouselContainer'
import { PaginationDots } from './elements/PaginationDots'
import * as Styled from './styles'

interface CarouselProps {
  children: ReactNode
  pauseAutoSlide?: boolean
}

export const Carousel = ({ children, pauseAutoSlide }: CarouselProps): ReactElement => {
  const carouselRef = useRef<HTMLDivElement>(null)
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)

  const slides = React.Children.toArray(children)
  const realCount = slides.length

  const extendedSlides = useMemo(() => {
    if (realCount === 0) {
      return []
    }
    return [slides[realCount - 1], ...slides, slides[0]]
  }, [slides, realCount])

  // Start at index 1 (the first real slide)
  const [currentIndex, setCurrentIndex] = useState(1)
  // This state lets us disable the CSS transition temporarily when “jumping”
  const [disableTransition, setDisableTransition] = useState(false)
  // Calculate the active dot (adjusting for the clone at the start)
  const activeDot = (currentIndex - 1 + realCount) % realCount

  const resetTimer = useCallback(() => {
    if (pauseAutoSlide) {
      return
    }

    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 1)
    }, 5000)
  }, [pauseAutoSlide])

  const stopTimer = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }, [])

  const navigateToSelectedSlide = useCallback(
    (i: number) => {
      // Adjust by +1 because index 0 is a clone of the last slide.
      setCurrentIndex(i + 1)
      resetTimer()
    },
    [resetTimer],
  )

  // When a transition ends, check if we’re at a clone and “jump” to the real slide.
  const handleTransitionEnd = useCallback(() => {
    if (currentIndex >= extendedSlides.length - 1) {
      // If we reached the clone of the first slide, jump back to the first real slide.
      setDisableTransition(true)
      setCurrentIndex(1)
    } else if (currentIndex === 0) {
      // If we reached the clone of the last slide, jump to the last real slide.
      setDisableTransition(true)
      setCurrentIndex(realCount)
    }
  }, [currentIndex, extendedSlides.length, realCount])

  // // After a jump, re-enable transitions.
  useEffect(() => {
    if (disableTransition) {
      const timeout = setTimeout(() => {
        setCurrentIndex(1)
        setDisableTransition(false)
      }, 50)
      return () => clearTimeout(timeout)
    }
  }, [disableTransition])

  useEffect(() => {
    // Start or stop the timer based on pauseAutoSlide
    if (!pauseAutoSlide) {
      resetTimer()
    } else {
      stopTimer()
    }

    // Handle tab visibility changes
    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopTimer()
      } else {
        resetTimer()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      stopTimer()
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [pauseAutoSlide, resetTimer, stopTimer])

  return (
    <Styled.CarouselWrapper onMouseEnter={stopTimer} onMouseLeave={resetTimer}>
      <CarouselContainer
        ref={carouselRef}
        currentIndex={currentIndex}
        disableTransition={disableTransition}
        onTransitionEnd={handleTransitionEnd}
      >
        {extendedSlides}
      </CarouselContainer>
      <PaginationDots count={realCount} currentIndex={activeDot} onPaginationItemClick={navigateToSelectedSlide} />
    </Styled.CarouselWrapper>
  )
}
