import { SkeletonBox } from '@design-system'

import * as Styled from './styles'

export const DashboardSkeleton = () => {
  return (
    <>
      <Styled.DashboardSkeletonBannerWrapper>
        <SkeletonBox height={120} fullWidth />
      </Styled.DashboardSkeletonBannerWrapper>

      <Styled.DashboardSkeletonContainer data-testid="dashboard-skeleton-container">
        <Styled.DashboardSkeletonGridItem isFullWidth>
          <SkeletonBox height={270} fullWidth />
        </Styled.DashboardSkeletonGridItem>

        <Styled.DashboardSkeletonGridItem>
          <SkeletonBox height={270} fullWidth />
        </Styled.DashboardSkeletonGridItem>

        <Styled.DashboardSkeletonGridItem>
          <SkeletonBox height={270} fullWidth />
        </Styled.DashboardSkeletonGridItem>

        <Styled.DashboardSkeletonGridItem>
          <SkeletonBox height={270} fullWidth />
        </Styled.DashboardSkeletonGridItem>

        <Styled.DashboardSkeletonGridItem>
          <SkeletonBox height={270} fullWidth />
        </Styled.DashboardSkeletonGridItem>
      </Styled.DashboardSkeletonContainer>
    </>
  )
}
