import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

import { BohrScanResult } from '../../../../../types/bohrScanResult'
import { Bill } from '../../../types/bill'

enum BillDataTrackingStatus {
  Manual = 'manual',
  Prefilled = 'prefilled',
  Overwritten = 'overwritten',
}

const getTrackedValueStatus = (
  bohrValue?: string | number | null,
  billValue?: string | number | null,
): BillDataTrackingStatus => {
  if (isEmpty(bohrValue) && !isEmpty(billValue)) {
    return BillDataTrackingStatus.Manual
  } else if (isEqual(bohrValue, billValue)) {
    return BillDataTrackingStatus.Prefilled
  } else {
    return BillDataTrackingStatus.Overwritten
  }
}

export const getBillDataTrackingStatuses = (bill?: Bill, bohrScanResult?: BohrScanResult) => {
  if (!bill || !bohrScanResult) {
    return {}
  }

  const prefillingTotalAmountStatus = getTrackedValueStatus(
    bohrScanResult.entities.total_incl_vat?.content,
    bill?.grossAmount,
  )

  const prefillingVatStatus = getTrackedValueStatus(bohrScanResult.entities.total_vat_amount?.content, bill?.tax)
  const prefillingDateStatus = getTrackedValueStatus(bohrScanResult.entities.issue_date?.content, bill?.entryDate)
  const prefillingDueDateStatus = getTrackedValueStatus(bohrScanResult.entities.due_date?.content, bill?.dueDate)
  const prefillingCurrencyStatus = getTrackedValueStatus(bohrScanResult.entities.currency?.content, bill?.currencyId)
  const allStatusesExceptVat = [
    prefillingTotalAmountStatus,
    prefillingDateStatus,
    prefillingDueDateStatus,
    prefillingCurrencyStatus,
  ]
  const isAnyStatusOverwritten = allStatusesExceptVat.some((status) => status === BillDataTrackingStatus.Overwritten)
  const isAnyStatusPrefilled = allStatusesExceptVat.some((status) => status === BillDataTrackingStatus.Prefilled)

  let prefillingOverallStatus = BillDataTrackingStatus.Manual
  if (isAnyStatusOverwritten) {
    prefillingOverallStatus = BillDataTrackingStatus.Overwritten
  } else if (isAnyStatusPrefilled) {
    prefillingOverallStatus = BillDataTrackingStatus.Prefilled
  }

  return {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    prefilling_overall_status: prefillingOverallStatus,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    prefilling_total_amount_status: prefillingTotalAmountStatus,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    prefilling_vat_status: prefillingVatStatus,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    prefilling_due_date_status: prefillingDueDateStatus,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    prefilling_date_status: prefillingDateStatus,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    prefilling_currency_status: prefillingCurrencyStatus,
  }
}
