import { useMutation } from 'react-query'

import { queryClient } from '../../../../config/queryClient'
import { CustomEvent } from '../../../../enums/customEvent'
import { QueryKeys } from '../../../../enums/queryKeys'
import { dispatchCustomEvent } from '../../../../utils/customEvent'
import { trackError } from '../../../../utils/trackError'
import { OrganizationSettingProps, OrganizationSettingsResponseData, setOrganizationSetting } from '../query-api'
import { OrganizationSettings } from '../types/organizationSettings'
import { OrganizationSettingsError } from '../types/organizationSettingsError'
import { useUserOrganization } from '../userOrganizationContext'

interface UseUpdateOrganizationSettingsProps {
  onError?: (
    error: OrganizationSettingsError,
    variables?: OrganizationSettingProps,
    context?: OrganizationSettingsResponseData,
  ) => void
  onSettled?: (data?: OrganizationSettingsResponseData) => void
  onSuccess?: () => void
  onMutate?: (
    setting?: OrganizationSettingProps,
  ) => OrganizationSettingsResponseData | Promise<OrganizationSettingsResponseData>
  shouldRefetch?: boolean
}

export const useUpdateOrganizationSettings = (props?: UseUpdateOrganizationSettingsProps) => {
  const { onError, onMutate, onSuccess, onSettled, shouldRefetch = true } = props || {}
  const { organization } = useUserOrganization()

  const {
    mutate: update,
    isLoading: isProcessing,
    ...rest
  } = useMutation(setOrganizationSetting, {
    onMutate: async (setting) => {
      onMutate?.(setting)

      await queryClient.cancelQueries([QueryKeys.UserOrganizationSettings, organization?.id])

      const previousSettings: OrganizationSettingsResponseData = queryClient.getQueryData([
        QueryKeys.UserOrganizationSettings,
        organization?.id,
      ]) as OrganizationSettingsResponseData

      if (!previousSettings) {
        return
      }

      const updatedSettings: OrganizationSettingsResponseData = {
        settings: {
          ...previousSettings.settings,
          [setting.key]: setting.value,
        },
      }

      queryClient.setQueryData([QueryKeys.UserOrganizationSettings, organization?.id], updatedSettings)

      dispatchCustomEvent<Partial<OrganizationSettings>>(CustomEvent.OrganizationSettingsUpdatedInReactApp, {
        ...previousSettings.settings,
        [setting.key]: setting.value,
      })

      return previousSettings
    },
    onSettled: (data) => {
      onSettled?.(data)

      const updateOrganizationSettingsResponseData = data?.settings

      if (shouldRefetch) {
        queryClient.invalidateQueries(QueryKeys.UserOrganizationSettings)
      }

      dispatchCustomEvent<Partial<OrganizationSettings>>(
        CustomEvent.OrganizationSettingsUpdatedInReactApp,
        updateOrganizationSettingsResponseData,
      )
    },
    onError: (error: OrganizationSettingsError, variables, context) => {
      onError?.(error, variables, context)

      queryClient.setQueryData([QueryKeys.UserOrganizationSettings, organization?.id], context)
      trackError(error)
    },
    onSuccess,
  })

  return { update, isProcessing, ...rest }
}
