import { useCallback } from 'react'

import { useSegment } from '../../../hooks'
import { useDashboardSettings } from '../contexts/dashboardSettingsContext'
import { groupWidgets } from '../elements/Widgets/elements/VisibleWidgets/elements/VisibleWidgetsList/utils/groupWidgets'
import { Widget } from '../types/widget'
import { WidgetId } from '../types/widgetId'
import { useDefaultWidgets } from './useDefaultWidgets'
import { useUpdateOrganizationSettingsWidgets } from './useUpdateOrganizationSettingsWidgets'

export const useWidgetsActions = () => {
  const { widgets, setWidgets } = useDashboardSettings()
  const { updateOrganizationSettingsWidgets } = useUpdateOrganizationSettingsWidgets()
  const defaultWidgets = useDefaultWidgets()
  const { track } = useSegment()

  const trackDashboardConfigurationChange = useCallback(
    ({ position, widgetId }: { position: number | string; widgetId: WidgetId }) => {
      track('xxx - bookkeeping - Dashboard Configuration Changed', {
        widget: widgetId,
        position,
      })
    },
    [track],
  )

  const trackDashboardActionPerformed = useCallback(
    ({ action, widgetId }: { action: number; widgetId: WidgetId }) => {
      track('xxx - bookkeeping - Dashboard Action Performed', {
        widget: widgetId,
        action,
      })
    },
    [track],
  )

  const trackDashboardCarouselUpsellActionPerformed = useCallback(
    ({ action, widget }: { action: number; widget: string }) => {
      track('xxx - bookkeeping - Dashboard Action Performed', {
        widget,
        action,
      })
    },
    [track],
  )

  const updateWidgetsAndSettings = useCallback(
    (updatedVisible: Widget[], updatedHidden: Widget[]) => {
      const groupedVisible = groupWidgets(updatedVisible).flat()
      const groupedHidden = groupWidgets(updatedHidden).flat()
      const updatedWidgets = {
        visible: groupedVisible,
        hidden: groupedHidden,
      }

      setWidgets(updatedWidgets)
      updateOrganizationSettingsWidgets({ widgets: updatedWidgets, debounce: true })
    },
    [setWidgets, updateOrganizationSettingsWidgets],
  )

  const hideWidget = useCallback(
    (id: WidgetId) => {
      const updatedHidden = [...widgets.hidden, { id }]
      const updatedVisible = widgets.visible.filter((widget) => widget.id !== id)
      trackDashboardConfigurationChange({ position: 'hide', widgetId: id })
      updateWidgetsAndSettings(updatedVisible, updatedHidden)
    },
    [widgets.hidden, widgets.visible, trackDashboardConfigurationChange, updateWidgetsAndSettings],
  )

  const showWidget = useCallback(
    (id: WidgetId) => {
      const updatedHidden = widgets.hidden.filter((widget) => widget.id !== id)
      const updatedVisible = [{ id }, ...widgets.visible]
      trackDashboardConfigurationChange({ position: 'unhide', widgetId: id })
      updateWidgetsAndSettings(updatedVisible, updatedHidden)
    },
    [widgets.hidden, widgets.visible, trackDashboardConfigurationChange, updateWidgetsAndSettings],
  )

  const resetWidgets = useCallback(() => {
    setWidgets(defaultWidgets)
    updateOrganizationSettingsWidgets({ widgets: defaultWidgets })
  }, [defaultWidgets, setWidgets, updateOrganizationSettingsWidgets])

  return {
    hideWidget,
    showWidget,
    resetWidgets,
    trackDashboardConfigurationChange,
    trackDashboardActionPerformed,
    trackDashboardCarouselUpsellActionPerformed,
  }
}
