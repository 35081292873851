import { Scope } from '../../../enums/scope'
import { WidgetId } from '../types/widgetId'

export const widgetIdToScopes: Record<WidgetId, Scope | Scope[] | null> = {
  BankIntegration: Scope.BankSyncRead,
  Bills: null,
  CarouselUpsell: null,
  FindAccountant: Scope.UserWrite,
  GetStarted: null,
  Graph: Scope.ReportsRead,
  Invoices: Scope.InvoiceRead,
  Overview: Scope.ReportsRead,
  Tasks: Scope.TaskWrite,
  TopCustomers: Scope.ContactRead,
  TopVendors: Scope.ContactRead,
  Vat: Scope.VatReturnRead,
}
