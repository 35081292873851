import { HelpModal } from '@components'
import { Button, Color, useModal } from '@design-system'

import React, { ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { EmberRoute } from '../../../../../enums/emberRoute'
import { ModalId } from '../../../../../enums/modalId'
import { Scope } from '../../../../../enums/scope'
import { useEmberRouteUrl } from '../../../../../hooks/routing/useEmberRouteUrl'
import { useOrganizationViewUrl } from '../../../../../hooks/routing/useOrganizationViewUrl'
import { isAuthorized } from '../../../../../utils/isAuthorized'
import { reactRoute } from '../../../../../utils/routing/reactRoute'
import { widgetId } from '../../../constants/widgetId'
import { useWidgetsActions } from '../../../hooks/useWidgetsActions'
import { CarouselUpsellWidgetId } from '../enums/carouselUpsellWidgetId'

export interface CarouselUpsellWidget {
  backgroundColor: Color
  description: string
  id: CarouselUpsellWidgetId
  img: { src: string; alt: string }
  scope: Scope | null
  subtitle: string
  renderButton: () => ReactNode
  title: string
}

export const useCarouselUpsellWidgets = () => {
  const { open } = useModal(ModalId.HelpModal)
  const { t } = useTranslation()

  const { url: salaryIntegrationUrl } = useOrganizationViewUrl(reactRoute.salary.getMainRoute())
  const { url: integrationsUrl } = useEmberRouteUrl(EmberRoute.Integrations)
  const { url: settingsOrganizationUsersUrl } = useEmberRouteUrl(EmberRoute.SettingsOrganizationUsers)
  const { trackDashboardCarouselUpsellActionPerformed } = useWidgetsActions()

  const trackUpsellWidgetAction = useCallback(
    (carouselUpsellWidgetId: CarouselUpsellWidgetId) => {
      trackDashboardCarouselUpsellActionPerformed({
        action: 1,
        widget: `${widgetId.CarouselUpsell} - ${carouselUpsellWidgetId}`,
      })
    },
    [trackDashboardCarouselUpsellActionPerformed],
  )

  const handleOnSupportButtonClick = useCallback(() => {
    trackUpsellWidgetAction(CarouselUpsellWidgetId.Support)
    open()
  }, [open, trackUpsellWidgetAction])

  const carouselUpsellWidgets: CarouselUpsellWidget[] = [
    {
      id: CarouselUpsellWidgetId.Integrations,
      scope: Scope.TokensWrite,
      subtitle: t('dashboard.widget.carousel-upsell.integrations.subtitle'),
      title: t('dashboard.widget.carousel-upsell.integrations.title'),
      description: t('dashboard.widget.carousel-upsell.integrations.description'),
      backgroundColor: Color.White,
      img: { src: '/static-widgets/integrations.svg', alt: 'Dashboard widget - integrations' },
      renderButton: () => (
        <Button
          variant="secondary"
          size="m"
          to={integrationsUrl}
          fullWidth={false}
          onClick={() => trackUpsellWidgetAction(CarouselUpsellWidgetId.Integrations)}
        >
          {t('dashboard.widget.carousel-upsell.integrations.button.label')}
        </Button>
      ),
    },
    {
      id: CarouselUpsellWidgetId.AddUsers,
      scope: Scope.UserWrite,
      subtitle: t('dashboard.widget.carousel-upsell.add_users.subtitle'),
      title: t('dashboard.widget.carousel-upsell.add_users.title'),
      description: t('dashboard.widget.carousel-upsell.add_users.description'),
      backgroundColor: Color.Purple20,
      img: { src: '/static-widgets/add-users.svg', alt: 'Dashboard widget - Add users' },
      renderButton: () => (
        <Button
          variant="secondary"
          size="m"
          to={settingsOrganizationUsersUrl}
          fullWidth={false}
          onClick={() => trackUpsellWidgetAction(CarouselUpsellWidgetId.AddUsers)}
        >
          {t('dashboard.widget.carousel-upsell.add_users.button.label')}
        </Button>
      ),
    },
    {
      id: CarouselUpsellWidgetId.Payroll,
      scope: Scope.SalaryWrite,
      subtitle: t('dashboard.widget.carousel-upsell.payroll.subtitle'),
      title: t('dashboard.widget.carousel-upsell.payroll.title'),
      description: t('dashboard.widget.carousel-upsell.payroll.description'),
      backgroundColor: Color.Green5,
      img: { src: '/static-widgets/payroll.svg', alt: 'Dashboard widget - payroll' },
      renderButton: () => (
        <Button
          variant="primary"
          size="m"
          to={salaryIntegrationUrl}
          fullWidth={false}
          onClick={() => trackUpsellWidgetAction(CarouselUpsellWidgetId.Payroll)}
        >
          {t('dashboard.widget.carousel-upsell.payroll.button.label')}
        </Button>
      ),
    },
    {
      id: CarouselUpsellWidgetId.Support,
      scope: null,
      subtitle: t('dashboard.widget.carousel-upsell.support.subtitle'),
      title: t('dashboard.widget.carousel-upsell.support.title'),
      description: t('dashboard.widget.carousel-upsell.support.description'),
      backgroundColor: Color.Gray30,
      img: { src: '/static-widgets/support.svg', alt: 'Dashboard widget - support' },
      renderButton: () => (
        <>
          <Button variant="secondary" size="m" onClick={handleOnSupportButtonClick} fullWidth={false}>
            {t('dashboard.widget.carousel-upsell.support.button.label')}
          </Button>
          <HelpModal />
        </>
      ),
    },
    {
      id: CarouselUpsellWidgetId.Feedback,
      scope: null,
      subtitle: t('dashboard.widget.carousel-upsell.feedback.subtitle'),
      title: t('dashboard.widget.carousel-upsell.feedback.title'),
      description: t('dashboard.widget.carousel-upsell.feedback.description'),
      backgroundColor: Color.White,
      img: { src: '/static-widgets/feedback.svg', alt: 'Dashboard widget - feedback' },
      renderButton: () => (
        <Button
          variant="secondary"
          size="m"
          href="https://form.typeform.com/to/ckHJuaAn"
          target="_blank"
          fullWidth={false}
          onClick={() => trackUpsellWidgetAction(CarouselUpsellWidgetId.Feedback)}
        >
          {t('dashboard.widget.carousel-upsell.feedback.button.label')}
        </Button>
      ),
    },
  ]

  const filteredByScopesCarouselUpsellWidgets: CarouselUpsellWidget[] = carouselUpsellWidgets.filter(
    (carouselUpsellWidget) => (carouselUpsellWidget.scope ? isAuthorized(carouselUpsellWidget.scope) : true),
  )

  return { carouselUpsellWidgets: filteredByScopesCarouselUpsellWidgets }
}
