import { ButtonDropdown, NavItem, useModal } from '@design-system'

import has from 'lodash/has'
import { ReactElement, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { useSegment } from '../../../../../../hooks'
import { IS_DEVELOPMENT } from '../../../../../../utils/isDevelopment'
import { useInvoiceEditor } from '../../contexts/InvoiceEditorContext'
import { useInvoiceEditorForm } from '../../contexts/invoiceEditorFormContext'
import { InvoiceEditorView } from '../../enums/invoiceEditorView'
import { useInvoiceEditorFormSubmit } from '../../hooks/useInvoiceEditorFormSubmit'
import { SendAsEInvoiceModal } from './elements/SendAsEInvoiceModal'
import { SendAsEmailModal } from './elements/SendAsEmailModal'
import { FinalizeActionDropdownItem } from './types/finalizeActionDropdownItem'

const SendAsEmailModalId = ModalId.InvoiceEditorSendAsEmailModal
const SendAsEInvoiceModalId = ModalId.InvoiceEditorSendAsEInvoiceModal

type ModalOpenActions = Partial<Record<FinalizeActionDropdownItem, () => void>>

const isActionModal = (modalActions: ModalOpenActions, actionId: FinalizeActionDropdownItem) =>
  has(modalActions, actionId) && typeof modalActions[actionId] === 'function'

export const InvoiceEditorSideActions = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { submit, isSubmitting } = useInvoiceEditorFormSubmit()
  const [selectedAction, setSelectedAction] = useState<FinalizeActionDropdownItem>('approveAndClose')

  const { open: OpenSendAsEmailModal } = useModal(SendAsEmailModalId)
  const { open: OpenSendAsEInvoiceModal } = useModal(SendAsEInvoiceModalId)

  const modalActions = useMemo(
    (): ModalOpenActions => ({
      sendAsEmail: OpenSendAsEmailModal,
      sendAsEInvoice: OpenSendAsEInvoiceModal,
    }),
    [OpenSendAsEInvoiceModal, OpenSendAsEmailModal],
  )

  const { setView, view } = useInvoiceEditor()
  const { validate } = useInvoiceEditorForm()

  // action items that are only to be presented in the DEVELOPMENT env
  // for easier dev purposes
  const devOnlyDropdownItems = useMemo(
    (): NavItem<FinalizeActionDropdownItem>[] => [
      {
        id: 'sendAsEmail',
        // TODO: add proper translation
        children: 'Send as email',
        iconLeft: 'envelope',
        value: 'sendAsEmail',
      },
      {
        id: 'sendAsEInvoice',
        // TODO: add proper translation
        children: 'Send as e-invoice',
        iconLeft: 'documentWithDots',
        value: 'sendAsEInvoice',
      },
    ],
    [],
  )

  const dropdownItems = useMemo(
    (): NavItem<FinalizeActionDropdownItem>[] => [
      ...(IS_DEVELOPMENT ? devOnlyDropdownItems : []),
      {
        id: 'downloadAsPdf',
        children: t('external_invoices.editor.side_actions.download_as_pdf'),
        iconLeft: 'arrowPointingDown',
        value: 'downloadAsPdf',
      },
      {
        id: 'approveAndClose',
        children: t('external_invoices.editor.side_actions.approve_and_close'),
        iconLeft: 'checkStar',
        value: 'approveAndClose',
      },
    ],
    [devOnlyDropdownItems, t],
  )

  const handleSubmit = useCallback(() => {
    submit[selectedAction]()
  }, [selectedAction, submit])

  const handleSelect = useCallback(
    async (_: string, value: FinalizeActionDropdownItem) => {
      const isFormValid = await validate()

      track('XXX - Invoicing Core - Invoice Editor - Finalize action clicked', { option: value })

      if (!isFormValid) {
        if (view === InvoiceEditorView.Preview) {
          setView(InvoiceEditorView.Editor)
        }
        return
      }

      setSelectedAction(value)

      if (isActionModal(modalActions, value)) {
        modalActions[value]?.()
        return
      }

      handleSubmit()
    },
    [handleSubmit, modalActions, setView, track, validate, view],
  )

  const handleFinalizeButtonDropdownClick = useCallback(() => {
    track('XXX - Invoicing Core - Invoice Editor - Finalize button dropdown clicked')
  }, [track])

  return (
    <>
      <ButtonDropdown
        loading={isSubmitting}
        items={dropdownItems}
        onSelect={handleSelect}
        onClick={handleFinalizeButtonDropdownClick}
      >
        {t('external_invoices.editor.side_actions.finalize')}
      </ButtonDropdown>
      {IS_DEVELOPMENT ? <SendAsEmailModal id={SendAsEmailModalId} /> : null}
      {IS_DEVELOPMENT ? <SendAsEInvoiceModal id={SendAsEInvoiceModalId} /> : null}
    </>
  )
}
