import { IconButtonWithLabel, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'

import { OPEN_HELP_MODAL_SHORTCUT } from '../../../../../../constants/shortcuts'
import { ModalId } from '../../../../../../enums/modalId'
import { useOrganizationBrand } from '../../../../../../hooks/useOrganizationBrand'
import { HelpModal } from '../../../../../Modal'

export const HelpButton = (): ReactElement => {
  const { t } = useTranslation()
  const { open: handleOpenHelpModal, close: handleCloseHelpModal, isOpen } = useModal(ModalId.HelpModal)
  const { isBrandAgeras } = useOrganizationBrand()

  const handleShortcutPress = useCallback(() => {
    if (isBrandAgeras) {
      return
    }

    if (isOpen) {
      handleCloseHelpModal()
    } else {
      handleOpenHelpModal()
    }
  }, [isBrandAgeras, isOpen, handleCloseHelpModal, handleOpenHelpModal])

  useHotkeys(OPEN_HELP_MODAL_SHORTCUT, handleShortcutPress, { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] }, [])

  return (
    <>
      <IconButtonWithLabel icon="questionCircle" onClick={handleOpenHelpModal} active={isOpen}>
        {t('help')}
      </IconButtonWithLabel>
      <HelpModal />
    </>
  )
}
