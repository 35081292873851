import { useCallback } from 'react'

import { Widget } from '../types/widget'
import { usePermanentlyHiddenWidgets } from './usePermanentlyHiddenWidgets'

export const useFilterByPermanentlyHiddenWidgets = () => {
  const permanentlyHiddenWidgets = usePermanentlyHiddenWidgets()

  const filterByPermanentlyHiddenWidgets = useCallback(
    <T extends Widget>(widgets: T[]): T[] => {
      return widgets.filter((widget) => !permanentlyHiddenWidgets[widget.id])
    },
    [permanentlyHiddenWidgets],
  )

  return { filterByPermanentlyHiddenWidgets }
}
