import { useModalContentToggler } from '@components'
import { Button, ButtonsGroup, useModal } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { ModalId } from '../../../../../../enums/modalId'
import { useSegment } from '../../../../../../hooks'
import { useInvalidateBills } from '../../../../hooks/useInvalidateBills'
import { useCreateBillsAndBankLinesReconciliation } from '../../../../hooks/useReconcileBillsAndBankLines'
import { ReconcilableParameters } from '../../../../query-api'
import { useBankLines } from '../../contexts/bankLinesContext'
import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { useMultipleBills } from '../../contexts/multipleBillsContext'
import { BillReconciliationModalStateId } from '../../enums/billReconciliationModalStateId'
import { PaymentRegistrationSuccessState } from '../../enums/paymentRegistrationSuccessState'
import { convertBillsIdsToReconciliationSubjects } from '../../utils/convertBillsIdsToReconciliationSubjects'
import { notifyPaymentRegistrationError } from '../../utils/notifyPaymentRegistrationError'
import { notifyPaymentRegistrationSuccess } from '../../utils/notifyPaymentRegistrationSuccess'

export const BankLinesModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const { initialBill, selectedDifferenceType } = useBillReconciliation()
  const { hasBankLinesDifference, selectedBankLinesIds } = useBankLines()
  const { selectedBillsIds, isCreditNoteSelected } = useMultipleBills()
  const { setActiveState } = useModalContentToggler()
  const { invalidateBills, invalidateBill } = useInvalidateBills()
  const { close: closeBillReconciliationModal } = useModal(ModalId.BillReconciliationModal, {
    onClose: () => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      track('xxx - reporting - bills -  Pay and reconcile modal closed', { bill_id: initialBill?.id })
    },
  })

  const { create: createBillsAndBankLinesReconcilation, isProcessing } = useCreateBillsAndBankLinesReconciliation({
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      track('Bill Payment Created (Client)', { bill_id: initialBill?.id })
      notifyPaymentRegistrationSuccess(PaymentRegistrationSuccessState.RegisteredAndReconciled, t)
      invalidateBills()
      invalidateBill(initialBill?.id)
      closeBillReconciliationModal()
    },
    onError: () => notifyPaymentRegistrationError(t),
  })

  const organizationId = organization?.id
  const isEnterPaymentButtonDisabled = useMemo(
    () => (hasBankLinesDifference && !selectedDifferenceType) || !selectedBankLinesIds?.length,
    [hasBankLinesDifference, selectedBankLinesIds?.length, selectedDifferenceType],
  )

  const handleEnterPaymentButtonClick = useCallback(() => {
    if (!selectedBankLinesIds?.length || !selectedBillsIds?.length || !organizationId) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    track('xxx - reporting - bills - Enter payment and reconcile button clicked', { bill_id: initialBill?.id })

    const billReconciliationSubjects = convertBillsIdsToReconciliationSubjects(selectedBillsIds)
    const reconcilableParameters: ReconcilableParameters = {}

    if (selectedDifferenceType) {
      reconcilableParameters.differenceType = selectedDifferenceType
    }

    createBillsAndBankLinesReconcilation({
      bankLinesIds: selectedBankLinesIds,
      organizationId,
      subjects: billReconciliationSubjects,
      parameters: reconcilableParameters,
    })
  }, [
    createBillsAndBankLinesReconcilation,
    organizationId,
    selectedBankLinesIds,
    selectedBillsIds,
    selectedDifferenceType,
    track,
    initialBill?.id,
  ])

  const handleRegisterMultipleBillsButton = useCallback(() => {
    track('xxx - expenses - Bank line modal - register mutliple bills clicked')

    setActiveState(BillReconciliationModalStateId.MultipleBills)
  }, [setActiveState, track])

  return (
    <ButtonsGroup>
      {!isCreditNoteSelected && (
        <Button onClick={handleRegisterMultipleBillsButton} variant="text" icon="papersPile">
          {t('bill.payment_reconcile.button.register_multiple_bills')}
        </Button>
      )}
      <Button onClick={handleEnterPaymentButtonClick} disabled={isEnterPaymentButtonDisabled} loading={isProcessing}>
        {t('bill.payment_reconcile.button.enter_payment_and_reconcile')}
      </Button>
    </ButtonsGroup>
  )
}
