import React, { ReactElement } from 'react'

import { ReactComponent as SupportIllustration } from '../../../../../assets/images/support-illustration.svg'
import { getImageClassName } from '../../../../../utils/getClassName'
import { AlgoliaSearch } from '../AlgoliaSearch'
import * as Styled from './styles'

export const HelpModalContent = (): ReactElement => (
  <Styled.HelpModalContentWrapper>
    <AlgoliaSearch />
    <Styled.ModalIllustrationWrapper className={getImageClassName()}>
      <SupportIllustration />
    </Styled.ModalIllustrationWrapper>
  </Styled.HelpModalContentWrapper>
)
