import { createContext, ReactElement, ReactNode, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useForm } from '../../../../../../../../../hooks'
import { MissingContactDetailsForm } from '../elements/SendAsEInvoiceModalComponent/types/MissingContactDetailsForm'
import { MissingContactDetailsFormSchema } from '../elements/SendAsEInvoiceModalComponent/types/MissingContactDetailsFormSchema'
import { getDefaultValues } from '../elements/SendAsEInvoiceModalComponent/utils/getDefaultValues'
import { getMissingContactDetailsFormSchema } from '../elements/SendAsEInvoiceModalComponent/utils/getMissingContactDetailsFormSchema'

interface ContextState {
  isSubmitting: boolean
  submitForm: () => void
}

const MissingContactDetailsFormContext = createContext<ContextState | undefined>(undefined)

interface MissingContactDetailsFormProviderProps {
  children?: ReactNode
}

export const MissingContactDetailsFormContextProvider = ({
  children,
}: MissingContactDetailsFormProviderProps): ReactElement => {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  // const { submit } = useInvoiceEditorFormSubmit()

  const { Form, handleSubmit } = useForm<MissingContactDetailsFormSchema>({
    defaultValues: getDefaultValues(),
    validationSchema: getMissingContactDetailsFormSchema(t),
  })

  const handleMissingContactDetailsFormSubmit = useCallback((formData: MissingContactDetailsForm) => {
    setIsSubmitting(true)
    // eslint-disable-next-line no-console
    console.log('formData:', formData)

    // TODO: use when we have the form ready
    // submit.updateContactDetails({
    //   updateContactDetailsFormData: formData
    // })
  }, [])

  const submitForm = useCallback(() => {
    handleSubmit(handleMissingContactDetailsFormSubmit)()
  }, [handleSubmit, handleMissingContactDetailsFormSubmit])

  return (
    <MissingContactDetailsFormContext.Provider value={{ isSubmitting, submitForm }}>
      <Form>{children}</Form>
    </MissingContactDetailsFormContext.Provider>
  )
}

export const useMissingContactDetailsForm = () => {
  const context = useContext(MissingContactDetailsFormContext)

  if (!context) {
    throw new Error('MissingContactDetailsFormContextProvider is missing in the module!')
  }

  return context
}
