import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { SettingsRoute } from '../../../enums/settingsRoute'
import { SettingsTabItem, UseSettingsTabItems } from '../types'

export const useUserSettingsTabItems = (): UseSettingsTabItems => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()

  const items: SettingsTabItem[] = [
    {
      icon: 'user',
      route: SettingsRoute.Profile,
      text: t('settings.user.profile'),
    },
    {
      icon: 'bell',
      route: SettingsRoute.ProfileNotifications,
      text: t('settings.user.notifications'),
      hidden: organization?.brand !== 'billy', // @todo it should be also handled inside routing
    },
    {
      icon: 'shield',
      route: SettingsRoute.ProfilePrivacy,
      text: t('settings.user.privacy'),
    },
  ]

  return { items }
}
