import { isAuthorized } from '../../../utils/isAuthorized'
import { widgetIdToScopes } from '../constants/widgetIdToScopes'
import { Widget } from '../types/widget'

export const filterWidgetsByScopes = <T extends Widget>(widgets: T[]): T[] => {
  return widgets.filter((widget) => {
    const scopes = widgetIdToScopes[widget.id]
    return scopes ? isAuthorized(scopes) : true
  })
}
