import { Select, SelectProps } from '@design-system'

import { forwardRef, ReactElement, Ref, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CountryIsoCode } from '../../../types/countryIsoCode'
import { LocaleIdentifier } from '../../../types/localeIdentifier'
import { CountryFlag } from '../../CountryFlag'
import { getLanguageItems } from './utils/getLanguageItems'

interface LanguageDropdownProps extends Omit<SelectProps<LocaleIdentifier>, 'items' | 'onSelect' | 'suffix'> {
  onSelect?: (value?: LocaleIdentifier) => void
}

export const LanguageSelect = forwardRef(
  ({ selectedId, onSelect, ...selectProps }: LanguageDropdownProps, ref: Ref<HTMLDivElement | null>): ReactElement => {
    const { t } = useTranslation()

    const languageItems = useMemo(() => getLanguageItems(t), [t])

    const selectedItem = useMemo(() => languageItems.find(({ id }) => selectedId === id), [selectedId, languageItems])

    const renderSuffix = useMemo(
      () => (selectedItem?.title ? <CountryFlag countryCode={selectedItem.title as CountryIsoCode} /> : null),
      [selectedItem],
    )

    const handleSelect = useCallback(
      (_id?: string, value?: LocaleIdentifier) => {
        onSelect?.(value)
      },
      [onSelect],
    )

    return (
      <Select
        {...selectProps}
        items={languageItems}
        dropdownSize={selectProps.dropdownSize || 'fitTrigger'}
        selectedId={selectedId}
        onSelect={handleSelect}
        suffix={renderSuffix}
        ref={ref}
      />
    )
  },
)
