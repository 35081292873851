import { Input } from '@design-system'

import { useFormContext } from '../../../../../../../../../../../../../../../../hooks'
import { MissingContactDetailsFormSchema } from '../../../../../../types/MissingContactDetailsFormSchema'

export const ContactIban = () => {
  const { FormItem } = useFormContext<MissingContactDetailsFormSchema>()

  // TODO: add translations
  return (
    <FormItem
      name="contactIban"
      label="IBAN"
      render={({ field: { onChange, ...rest } }) => {
        return (
          <Input
            {...rest}
            onChange={(contactIban) => {
              onChange(contactIban)
            }}
          />
        )
      }}
    />
  )
}
