import i18n, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'

import { Language } from '../types/language'
import { LocaleIdentifier } from '../types/localeIdentifier'
import { da, de, en, fr, nl } from './../locales'
import { setNumeralLocale } from './numeral'

const resources = {
  en: { translation: en },
  da: { translation: da },
  fr: { translation: fr },
  de: { translation: de },
  nl: { translation: nl },
}

export const defaultI18nOptions: InitOptions = {
  resources,
  lng: 'da',
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
}

i18n.use(initReactI18next).init(defaultI18nOptions)

// Initialise numeral to the same locale as i18n to avoid inconsistencies
setNumeralLocale('da')

export const locales: Record<Language, LocaleIdentifier> = {
  da: 'da_DK',
  en: 'en_US',
  de: 'de_DE',
  fi: 'fi_FI',
  fr: 'fr_FR',
  nl: 'nl_NL',
  sv: 'sv_SE',
}

export default i18n
