import { Button, ButtonsGroup, IconButton, Tooltip } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useDashboardSettings } from '../../contexts/dashboardSettingsContext'
import { useWidgetsActions } from '../../hooks/useWidgetsActions'

export const DashboardWidgetsEditAction = (): ReactElement => {
  const { t } = useTranslation()
  const { resetWidgets } = useWidgetsActions()
  const { isEditMode, setIsEditMode } = useDashboardSettings()

  const handleEditModeClick = useCallback(() => {
    setIsEditMode(!isEditMode)
  }, [isEditMode, setIsEditMode])

  const handleResetClick = useCallback(() => {
    resetWidgets()
  }, [resetWidgets])

  return (
    <ButtonsGroup>
      <Tooltip label={t('customize.dashboard.tooltip')}>
        <IconButton
          size="m"
          icon="settings"
          onClick={handleEditModeClick}
          active={isEditMode}
          data-testid="dashboard-widgets-edit-button"
        />
      </Tooltip>
      {isEditMode && (
        <Button size="m" icon="roundedArrows" onClick={handleResetClick} variant="text">
          {t('dashboard.widget.actions.reset')}
        </Button>
      )}
    </ButtonsGroup>
  )
}
