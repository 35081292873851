import numeral, { locales } from 'numeral'
import 'numeral/locales/da-dk'
import 'numeral/locales/de'
import 'numeral/locales/en-gb'
import 'numeral/locales/fr'
import 'numeral/locales/nl-nl'

// numeral locales use the form <language>-<country>, whereas we define the locale in our app as <language> (from i18n)
// What the following code does is go through the registered locales in numeral, extracts the language from the numeral
// locales then re-registers them using the language only without the country if the language locale is not there
for (const locale of Object.keys(locales)) {
  const [language] = locale.split('-')
  if (!locales[language]) {
    numeral.register('locale', language, locales[locale])
  }
}

// Numeral will fail when we try to change the locale and it is not defined. As we may have i18n locales in the future which
// are not installed in numeral, we overwrite the locale method to fail gracefully instead of breaking the app
const setNumeralLocale = (locale: string): string | void => {
  if (locales[locale]) {
    return numeral.locale(locale)
  }

  // Try and set the language without country specification
  const [language] = locale.split('-')
  if (locales[language]) {
    return numeral.locale(language)
  }

  console.warn(`Attempting to change to a non-existing numeral locale: ${locale}. Locale not changed.`)
}

export { setNumeralLocale }
