import { Button, notify, Text } from '@design-system'

import React, { ReactElement, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../config/queryClient'
import { NotificationKeys } from '../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../enums/queryKeys'
import { formatDate } from '../../../../utils'
import { widgetId } from '../../constants/widgetId'
import { Widget } from '../../elements/Widget'
import { useWidgetsActions } from '../../hooks/useWidgetsActions'
import { TaskList } from './elements/taskList/TaskList'
import { TaskModal } from './elements/taskModal/TaskModal'
import { TasksActionPoint } from './enums/tasksActionPoint'
import { TaskState } from './enums/taskState'
import { createTask, fetchTasks, updateTaskApproval, updateTaskCompletion } from './query-api'
import * as Styled from './styles'
import { Task } from './types/task'

interface FetchedTasks {
  data: Task[]
}

export const Tasks = (): ReactElement => {
  const { t } = useTranslation()
  const [isApproveTaskModalVisible, setIsApproveTaskModalVisible] = useState(false)
  const [isConfirmTaskModalVisible, setIsConfirmTaskModalVisible] = useState(false)
  const [taskIdEditing, setTaskIdEditing] = useState('')
  const [inDraftMode, setInDraftMode] = useState(false)
  const [draftDescription, setDraftDescription] = useState('')
  const [draftDeadline, setDraftDeadline] = useState('')

  const { organization } = useUserOrganization()
  const { trackDashboardActionPerformed } = useWidgetsActions()

  const queryKey = useMemo(() => [QueryKeys.Tasks, organization?.id], [organization?.id])

  const { data: tasks, isLoading: loadingTasks } = useQuery(queryKey, () => fetchTasks(organization?.id || ''), {
    enabled: !!organization,
  })

  const updateTaskCompletionMutation = useMutation(
    (taskId: string) => updateTaskCompletion(taskId, organization?.id || ''),
    {
      onSuccess: () => {
        queryClient.cancelQueries(queryKey)

        queryClient.setQueryData(queryKey, () => {
          if (tasks) {
            const updatedTasks = [...tasks.data]
            const objIndex = updatedTasks.findIndex((obj) => obj.id === taskIdEditing)
            updatedTasks[objIndex].state = TaskState.Completed
            return { data: [...updatedTasks] }
          }
        })
        notify({
          id: NotificationKeys.DashboardWidgetTaskComplete,
          message: t('dashboard.widget.tasks.success.completed'),
          variant: 'success',
        })
      },
      onError: () => {
        notify({
          id: NotificationKeys.DashboardWidgetTaskComplete,
          message: t('dashboard.widget.tasks.error.completed'),
          variant: 'error',
        })
      },
    },
  )

  const updateTaskApprovalMutation = useMutation(
    (taskId: string) => updateTaskApproval(taskId, organization?.id || ''),
    {
      onSuccess: () => {
        queryClient.cancelQueries(queryKey)

        queryClient.setQueryData(queryKey, () => {
          if (tasks) {
            const updatedTasks = [...tasks.data]
            const objIndex = updatedTasks?.findIndex((obj) => obj.id === taskIdEditing)
            updatedTasks.splice(objIndex, 1)
            return { data: [...updatedTasks] }
          }
        })
        notify({
          id: NotificationKeys.DashboardWidgetTaskApprove,
          message: t('dashboard.widget.tasks.success.approved'),
          variant: 'success',
        })
      },
      onError: () => {
        notify({
          id: NotificationKeys.DashboardWidgetTaskApprove,
          message: t('dashboard.widget.tasks.error.approved'),
          variant: 'error',
        })
      },
    },
  )

  const createTaskMutation = useMutation(
    () =>
      createTask(organization?.id || '', {
        description: draftDescription,
        deadline: draftDeadline.length > 0 ? draftDeadline : undefined,
      }),
    {
      onSuccess: (newTask) => {
        queryClient.cancelQueries(queryKey)

        queryClient.setQueryData<FetchedTasks | undefined>(queryKey, (oldTasks) => {
          if (oldTasks) {
            return {
              ...oldTasks,
              data: [
                {
                  deadline: draftDeadline,
                  description: draftDescription,
                  id: newTask.data.id,
                  state: TaskState.Pending,
                } as Task,
                ...oldTasks.data,
              ],
            }
          }
        })
      },
      onSettled: (data) => {
        setDraftDescription('')
        setDraftDeadline('')
        setInDraftMode(false)
        notify({
          id: NotificationKeys.DashboardWidgetTaskCreate,
          message: t('dashboard.widget.tasks.success.created'),
          variant: 'success',
        })
      },
      onError: () => {
        notify({
          id: NotificationKeys.DashboardWidgetTaskCreate,
          message: t('dashboard.widget.tasks.error.created'),
          variant: 'error',
        })
      },
    },
  )

  const handleConfirm = (taskId: string) => {
    setTaskIdEditing(taskId)
    setIsConfirmTaskModalVisible(true)
  }

  const handleApprove = (taskId: string) => {
    setTaskIdEditing(taskId)
    setIsApproveTaskModalVisible(true)
  }

  const getCompletedPercentage = () => {
    if (!tasks?.data.length) {
      return
    }
    const completedTasks = tasks.data.filter((task: Task) => task?.state === TaskState.Completed).length
    return Math.round((completedTasks / tasks.data.length) * 100)
  }

  const approveTask = () => {
    updateTaskApprovalMutation.mutate(taskIdEditing)
    setTaskIdEditing('')
    setIsApproveTaskModalVisible(false)

    trackDashboardActionPerformed({ action: TasksActionPoint.ApproveTask, widgetId: widgetId.Tasks })
  }

  const confirmTask = () => {
    updateTaskCompletionMutation.mutate(taskIdEditing)
    setTaskIdEditing('')
    setIsConfirmTaskModalVisible(false)

    trackDashboardActionPerformed({ action: TasksActionPoint.Checkmark, widgetId: widgetId.Tasks })
  }

  const handleCreateTask = () => {
    if (draftDescription.trim().length > 0) {
      trackDashboardActionPerformed({ action: TasksActionPoint.Save, widgetId: widgetId.Tasks })
      createTaskMutation.mutate()
    }
  }

  const handleTaskItemCheckboxClick = (taskId?: string) => {
    if (!taskId) {
      return
    }

    setTaskIdEditing(taskId)
    setIsConfirmTaskModalVisible(true)
  }

  const handleSideButtonClick = () => {
    if (inDraftMode) {
      handleCreateTask()
    } else {
      setInDraftMode(true)
    }
  }

  const isLoading = loadingTasks || !organization

  return (
    <Widget
      title={t('dashboard.widget.tasks.title')}
      sideActions={
        <Button disabled={inDraftMode && !draftDescription.length} onClick={handleSideButtonClick} variant="secondary">
          {inDraftMode ? t('save') : t('dashboard.widget.tasks.add.cta')}
        </Button>
      }
    >
      {isLoading ? (
        <Styled.SkeletonContainer>
          <Styled.SkeletonText width={300} />
          <Styled.SkeletonText width={150} />
          <Styled.SkeletonText width={250} />
        </Styled.SkeletonContainer>
      ) : (
        <>
          <TaskList
            tasks={tasks?.data}
            isInDraftMode={inDraftMode}
            handleConfirm={handleConfirm}
            onCreateTask={handleCreateTask}
            onUpdateDraftDescription={(description) => setDraftDescription(description)}
            onUpdateDraftDeadline={(deadline: Date) => setDraftDeadline(`${formatDate(deadline, 'yyyy-MM-dd')}`)}
            handleApprove={handleApprove}
            onTaskItemCheckboxClick={handleTaskItemCheckboxClick}
          />

          {tasks?.data?.length ? (
            <Styled.Footer>
              <Text colorVariant="secondary" variant="micro">{`${getCompletedPercentage()}% ${t(
                'dashboard.widget.tasks.completed',
              )}`}</Text>
            </Styled.Footer>
          ) : null}
        </>
      )}

      <TaskModal
        header={t('dashboard.widget.tasks.approve_modal.title')}
        bodyText={t('dashboard.widget.tasks.approve_modal.info')}
        isOpen={isApproveTaskModalVisible}
        onConfirm={approveTask}
        onClose={() => setIsApproveTaskModalVisible(false)}
      />

      <TaskModal
        header={t('dashboard.widget.tasks.confirm_modal.title')}
        bodyText={t('dashboard.widget.tasks.confirm_modal.info')}
        isOpen={isConfirmTaskModalVisible}
        onConfirm={confirmTask}
        onClose={() => setIsConfirmTaskModalVisible(false)}
      />
    </Widget>
  )
}
