import { useMemo } from 'react'

import { useUserOrganizationSettings } from '@modules-deprecated/app/organization'

import { Widgets } from '../types/widgets'
import { modifyDashboardLayoutDataStructure, parseWidgetsList } from '../utils'
import { useDefaultWidgets } from './useDefaultWidgets'

export const usePrepareWidgets = () => {
  const defaultWidgets = useDefaultWidgets()
  const { organizationSettings } = useUserOrganizationSettings()

  const preparedWidgets = useMemo(() => {
    if (organizationSettings?.widgets) {
      return parseWidgetsList<Widgets>(organizationSettings?.widgets, {
        visible: [],
        hidden: [],
      })
    } else if (organizationSettings?.dashboardLayout) {
      return modifyDashboardLayoutDataStructure(organizationSettings?.dashboardLayout)
    } else {
      return defaultWidgets
    }
  }, [organizationSettings?.widgets, organizationSettings?.dashboardLayout, defaultWidgets])

  return { preparedWidgets }
}
