import { BorderRadius, Color, DefaultValue, FontSize, Spacing } from '@design-system'

import styled from '@emotion/styled'

interface CarouselWidgetContainerProps {
  backgroundColor?: string
}

export const CarouselWidgetContainer = styled.div<CarouselWidgetContainerProps>`
  display: flex;
  text-align: left;
  box-shadow: 0 2px 1px ${Color.Gray30};
  height: 270px;
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor ?? Color.White};
  padding: ${Spacing.XL} ${Spacing.XL} ${Spacing.XXL};
  border-radius: ${BorderRadius.L};
  gap: ${Spacing.M};
`

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 66%;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

export const ImageContainer = styled.div`
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Title = styled.h2`
  font-size: 26px;
  color: ${Color.DeepSeaGreen};
  font-family: ${DefaultValue.FontFamilyDisplay};
`

export const Description = styled.p`
  font-size: ${FontSize.TextNormal};
  line-height: 18px;
  color: ${Color.DeepSeaGreen};
`

export const Illustration = styled.img`
  width: 70%;
  height: 70%;
  min-width: 150px;
  min-height: 150px;
`
