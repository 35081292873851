export const calculatePaginationDotTranslateValue = (currentIndex: number, count: number) => {
  const visibleDots = 5
  const dotWidth = 6
  const dotSpacing = 6

  const maxIndex = Math.max(count - visibleDots, 0)
  const halfVisible = Math.floor(visibleDots / 2)

  // Adjust index to center the active dot
  const adjustedIndex = Math.max(0, Math.min(currentIndex - halfVisible, maxIndex))

  // Calculate translateX: move the dots such that the active dot stays centered
  return adjustedIndex * (dotWidth + dotSpacing)
}
