// IMPORTANT
// If you remove a Widget ID from here, make sure to add it to `notAllowedWidgetIds`.
// This is because when we remove a widget from this list and synchronize on the frontend,
// a user might still have that widget in their settings (e.g., in the hidden list),
// while we’ve re-added it to the default list as a visible widget.
import { WidgetId } from '../types/widgetId'

export const widgetId: Record<WidgetId, WidgetId> = {
  BankIntegration: 'BankIntegration',
  Bills: 'Bills',
  CarouselUpsell: 'CarouselUpsell',
  FindAccountant: 'FindAccountant',
  GetStarted: 'GetStarted',
  Graph: 'Graph',
  Invoices: 'Invoices',
  Overview: 'Overview',
  Tasks: 'Tasks',
  TopCustomers: 'TopCustomers',
  TopVendors: 'TopVendors',
  Vat: 'Vat',
} as const
