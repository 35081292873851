import { useCallback, useMemo } from 'react'

import { Widget } from '../types/widget'
import { Widgets } from '../types/widgets'
import { filterWidgetsByScopes } from '../utils/filterWidgetsByScopes'
import { useFilterWidgetsByBusinessRules } from './useFilterByBusinessRules'
import { useFilterByPermanentlyHiddenWidgets } from './useFilterByPermanentlyHiddenWidgets'

export const useApplyAllFiltersWidgets = () => {
  const { filterByPermanentlyHiddenWidgets } = useFilterByPermanentlyHiddenWidgets()
  const { filterWidgetsByBusinessRules } = useFilterWidgetsByBusinessRules()

  const filters = useMemo(
    () => [filterWidgetsByScopes, filterWidgetsByBusinessRules, filterByPermanentlyHiddenWidgets] as const,
    [filterWidgetsByBusinessRules, filterByPermanentlyHiddenWidgets],
  )

  const applyFilters = useCallback(
    <T extends Widget>(widgetsList: T[]): T[] => {
      return filters.reduce<T[]>((acc, filter) => filter(acc), widgetsList)
    },
    [filters],
  )

  const applyAllFilters = useCallback(
    (widgets: Widgets) => ({
      visible: applyFilters(widgets.visible),
      hidden: applyFilters(widgets.hidden),
    }),
    [applyFilters],
  )

  return { applyAllFilters }
}
