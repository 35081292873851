import { Modal } from '@design-system'

import { MissingContactDetailsForm } from './elements/MissingContactDetailsForm/MissingContactDetailsForm'

export const SendAsEInvoiceModalBody = () => {
  return (
    <Modal.Body>
      <MissingContactDetailsForm />
    </Modal.Body>
  )
}
