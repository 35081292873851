import { Badge, IconButton, useOnFocusedSubmit } from '@design-system'

import { MouseEvent, ReactElement, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useUmbrella } from '@modules-deprecated/app/umbrellas'
import { UmbrellaRoute } from '@views/umbrella/enums/UmbrellaRoute'

import { newMarketCountries } from '../../../../constants/newMarketCountries'
import { EmberRoute } from '../../../../enums/emberRoute'
import { useEmberRouteUrl } from '../../../../hooks/routing/useEmberRouteUrl'
import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { hashRouterLinkSecondClickFix } from '../../../../utils/hashRouterLinkSecondClickFix'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { Logo } from '../../../Logo'
import * as Styled from './styles'

interface MenuHeaderProps {
  onCloseClick?: () => void
}

export const MenuHeader = ({ onCloseClick }: MenuHeaderProps): ReactElement => {
  const logoWrapperRef = useRef<HTMLElement>(null)
  const { umbrella } = useUmbrella()
  const { organization } = useUserOrganization()
  const { t } = useTranslation()

  const isOrganizationLocked = organization && organization?.isLocked

  const { url: umbrellaUmbrelaUrl } = useEmberRouteUrl(EmberRoute.Umbrella, umbrella?.id)

  const { url: dashboardUrl } = useOrganizationViewUrl(reactRoute.dashboard.getMainRoute())
  const logoUrl = (umbrella ? `${umbrellaUmbrelaUrl}#${UmbrellaRoute.Organizations}` : dashboardUrl) || '/'
  const shouldShowBetaBadge = organization?.countryId && newMarketCountries.includes(organization?.countryId)

  const handleLogoClick = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      if (umbrella && umbrellaUmbrelaUrl) {
        hashRouterLinkSecondClickFix(event)
      }
    },
    [umbrella, umbrellaUmbrelaUrl],
  )

  const handleCloseClick = useCallback(() => {
    onCloseClick?.()
  }, [onCloseClick])

  const handleItemKeyDown = useOnFocusedSubmit(logoWrapperRef.current, handleLogoClick)

  return (
    <Styled.MenuHeader>
      <Styled.LogoWrapper>
        <Link
          to={logoUrl}
          tabIndex={0}
          onClick={isOrganizationLocked ? undefined : handleLogoClick}
          onKeyDown={handleItemKeyDown}
        >
          <Logo ref={logoWrapperRef} />
        </Link>

        {shouldShowBetaBadge && (
          <Styled.BadgeWrapper>
            <Badge>{t('beta')?.toUpperCase()}</Badge>
          </Styled.BadgeWrapper>
        )}
      </Styled.LogoWrapper>

      <Styled.CloseButtonWrapper onClick={handleCloseClick}>
        <IconButton icon="xSign" />
      </Styled.CloseButtonWrapper>
    </Styled.MenuHeader>
  )
}
