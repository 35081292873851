import { Space } from '@design-system'

import { ContactAddress } from './elements/ContactAddress'
import { ContactIban } from './elements/ContactIban'
import { ContactName } from './elements/ContactName'

export const MissingContactDetailsForm = () => {
  return (
    <>
      <ContactName />
      <Space />
      <ContactIban />
      <Space />
      <ContactAddress />
    </>
  )
}
