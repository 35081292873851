import { createContext, Dispatch, ReactElement, ReactNode, SetStateAction, useContext, useState } from 'react'

import { Widgets } from '../types/widgets'

interface ContextState {
  widgets: Widgets
  isEditMode: boolean
  setIsEditMode: Dispatch<SetStateAction<boolean>>
  setWidgets: Dispatch<SetStateAction<Widgets>>
}

const DashboardSettingsContext = createContext<ContextState | undefined>(undefined)

interface DashboardSettingsContextProviderProps {
  children?: ReactNode
}

export const DashboardSettingsContextProvider = ({ children }: DashboardSettingsContextProviderProps): ReactElement => {
  const [widgets, setWidgets] = useState<Widgets>({
    visible: [],
    hidden: [],
  })
  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  return (
    <DashboardSettingsContext.Provider
      value={{
        isEditMode,
        setIsEditMode,
        setWidgets,
        widgets,
      }}
    >
      {children}
    </DashboardSettingsContext.Provider>
  )
}

export const useDashboardSettings = () => {
  const context = useContext(DashboardSettingsContext)

  if (!context) {
    throw new Error('DashboardSettingsContextProvider is missing in the module!')
  }

  return context
}
