import { Badge, Button, Space, Text } from '@design-system'

import qs from 'qs'
import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useAgerasFinancing } from '@features/financingBanner/hooks/useAgerasFinancing'

import { useSegment } from '../../../../../hooks'
import { useAgerasFinanceCustomer } from '../../../../financing/AgerasFinanceLending'
import { ApprovalState } from '../../../../financing/AgerasFinanceLending/enums/approvalState'
import * as Styled from './styles'

const badgedStates = [ApprovalState.Draft, ApprovalState.Pending]
const closableStates = [ApprovalState.Draft]

export const FinancingApplication = (): ReactElement | null => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { customer, token, isCustomerLoading } = useAgerasFinanceCustomer()
  const { dismiss, isDismissed, isLoading: isAgerasFinancingLoading } = useAgerasFinancing()

  const approvalState = customer?.approval_state || ApprovalState.Draft
  const isDraft = approvalState === ApprovalState.Draft
  const isApproved = approvalState === ApprovalState.Approved
  const isRejected = approvalState === ApprovalState.Rejected || approvalState === ApprovalState.CustomerRejected
  const isClosable = closableStates.includes(approvalState)

  const badge = badgedStates.includes(approvalState) ? (
    <Badge variant="warning">{t(`dashboard.widget.financing_application.status.${approvalState}`)}</Badge>
  ) : null
  const title = (
    <Styled.TitleWrapper>
      <Text variant="h3">{t('dashboard.widget.financing_application.title')}</Text>
      <Space size="xs" horizontal />
      {badge}
    </Styled.TitleWrapper>
  )
  const content = (
    <Text>
      {t(
        isDraft
          ? 'dashboard.widget.financing_application.status.draft.description'
          : 'dashboard.widget.financing_application.status.pending.description',
      )}
    </Text>
  )

  const params = qs.stringify({
    /* eslint-disable @typescript-eslint/naming-convention */
    redirect_uri: `${window.location.origin}${window.location.pathname}?application_sent=1`,
    /* eslint-enable @typescript-eslint/naming-convention */
  })

  const draftCTALink = `${window.ENV.AGERAS_FINANCE_FRONTEND_URL}/invoice-financing?${params}#token=${token}`

  const handleOnClose = useCallback(() => {
    dismiss(true)
  }, [dismiss])

  if (isCustomerLoading || isAgerasFinancingLoading || !customer?.id || isApproved || isRejected || isDismissed) {
    return null
  }

  const handleDraftCTAClick = () => {
    track('Invoice Financing Integration Updated (Client)', { action: 'continue_application' })
    window.open(draftCTALink, '_blank')
  }

  return (
    <Styled.FinancingApplicationWrapper>
      {isClosable && <Styled.CloseIconButton icon="xSign" size="m" onClick={handleOnClose} />}
      <Styled.Content>
        <Text variant="h3" truncate>
          {title}
        </Text>
        {content}
      </Styled.Content>
      {isDraft && (
        <Button onClick={handleDraftCTAClick}>{t('dashboard.widget.financing_application.status.draft.cta')}</Button>
      )}
    </Styled.FinancingApplicationWrapper>
  )
}
