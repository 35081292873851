import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import React, { useEffect, useRef } from 'react'

import { useDashboardSettings } from '../../contexts/dashboardSettingsContext'
import { useUpdateOrganizationSettingsWidgets } from '../../hooks/useUpdateOrganizationSettingsWidgets'
import { HiddenWidgets } from './elements/HiddenWidgets'
import { VisibleWidgets } from './elements/VisibleWidgets'
import * as Styled from './styles'

export const Widgets = () => {
  const containerVisibleWidgetsRef = useRef<HTMLDivElement | null>(null)

  const { isEditMode, widgets } = useDashboardSettings()
  const { updateOrganizationSettingsWidgets } = useUpdateOrganizationSettingsWidgets()

  useEffect(() => {
    const currentRef = containerVisibleWidgetsRef.current

    if (!currentRef) {
      return
    }

    return monitorForElements({
      onDrop() {
        updateOrganizationSettingsWidgets({ widgets, debounce: true })
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgets])

  return (
    <Styled.WidgetsContainer ref={containerVisibleWidgetsRef} data-testid="widgets-container">
      <VisibleWidgets />
      {isEditMode && <HiddenWidgets />}
    </Styled.WidgetsContainer>
  )
}
