import { Button, ButtonsGroup, Space, Text } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { EmberRoute } from '../../../../../../enums/emberRoute'
import { useSegment } from '../../../../../../hooks'
import { useEmberRouteUrl } from '../../../../../../hooks/routing/useEmberRouteUrl'
import { getQueryParamsString } from '../../../../../../utils/getQueryParamsString'
import { USERFLOW_INVOICE_GUIDE_ID } from '../../../../constants/userflowIds'
import { widgetId } from '../../../../constants/widgetId'
import { useWidgetsActions } from '../../../../hooks/useWidgetsActions'
import { InvoicesActionPoint } from '../../enums/invoicesActionPoint'
import * as Styled from './styles'

export const OnboardingEmptyState = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const history = useHistory()
  const { url: newInvoiceUrl } = useEmberRouteUrl(EmberRoute.InvoicesNew)
  const { trackDashboardActionPerformed } = useWidgetsActions()

  const handleCreateInvoiceClick = useCallback(() => {
    track('xxx - hamster - tile experiment button clicked', {
      button: 'create invoice now',
    })
    trackDashboardActionPerformed({ action: InvoicesActionPoint.CreateInvoiceNow, widgetId: widgetId.Invoices })
    const queryParamsString = getQueryParamsString({ userflow: USERFLOW_INVOICE_GUIDE_ID })
    history.push(`${newInvoiceUrl}?${queryParamsString}`)
  }, [history, newInvoiceUrl, track, trackDashboardActionPerformed])

  const handleShowHow = useCallback(() => {
    track('xxx - hamster - tile experiment button clicked', {
      button: 'show me how',
    })
    trackDashboardActionPerformed({ action: InvoicesActionPoint.ShowMeHow, widgetId: widgetId.Invoices })
    window
      .open(
        'https://www.billy.dk/support/article/opret-forste-faktura?utm_source=userflow&utm_medium=internal&utm_campaign=fakturatile_show_me_how',
        '_blank',
      )
      ?.focus()
  }, [track, trackDashboardActionPerformed])

  return (
    <Styled.WidgetContentWrapper>
      <Styled.Header>
        <Space size="s" />
        <Text variant="displayS">{t('dashboard.widget.invoices.onboarding.title')}</Text>
        <Space />
        <Styled.DescriptionWrapper>
          <Text>{t('dashboard.widget.invoices.onboarding.description')}</Text>
        </Styled.DescriptionWrapper>
      </Styled.Header>
      <Styled.Footer>
        <ButtonsGroup>
          <Button onClick={handleCreateInvoiceClick}>
            {t('dashboard.widget.invoices.onboarding.start_invoicing')}
          </Button>
          <Button variant="secondary" onClick={handleShowHow}>
            {t('dashboard.widget.invoices.onboarding.show_me_how')}
          </Button>
        </ButtonsGroup>
      </Styled.Footer>
      <Styled.Illustration />
    </Styled.WidgetContentWrapper>
  )
}
