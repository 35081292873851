import { trackError } from '../../../utils/trackError'

export const parseWidgetsList = <T>(widgetsValue: string, defaultValue: T): T => {
  try {
    return JSON.parse(widgetsValue)
  } catch (error) {
    trackError(error)
    return defaultValue
  }
}
