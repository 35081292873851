import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ImageGraphDKComponent } from '../../../../assets/images/dashboard-empty-state/dk/graph.svg'
import { ReactComponent as ImageGraphENComponent } from '../../../../assets/images/dashboard-empty-state/en/graph.svg'
import { ReactComponent as ImageGraphFRComponent } from '../../../../assets/images/dashboard-empty-state/fr/graph.svg'
import { LocaleCode } from '../../../../enums/localeCode'
import { EmptyStateImage } from '../../elements/EmptyStateImage'
import { Widget } from '../../elements/Widget'
import { OverviewChart } from './elements/OverviewChart'
import { OverviewLabels } from './elements/OverviewLabels'
import { useOverview } from './hooks/useOverview'
import { ChartData } from './types/chartData'
import { OverviewStats } from './types/overviewStats'

const emptyImageByLanguage: Record<LocaleCode, ReactElement> = {
  [LocaleCode.DA]: <ImageGraphDKComponent />,
  [LocaleCode.EN_US]: <ImageGraphENComponent />,
  [LocaleCode.FR]: <ImageGraphFRComponent />,
}

export const Graph = (): ReactElement => {
  const { t } = useTranslation()
  const {
    chartData,
    code = LocaleCode.EN_US,
    currencyId,
    isLoading,
    result,
    totalRevenue,
    totalExpenses,
  } = useOverview()

  const localeCode = code as LocaleCode
  const isEmptyStateVisible = !isLoading && !chartData.find(({ result }: ChartData) => result)

  const stats: OverviewStats = useMemo(
    () => ({
      totalRevenue: {
        name: t('dashboard.widget.graph.revenue'),
        amount: totalRevenue,
        colorVariant: totalRevenue < 0 ? 'danger' : 'success',
      },
      totalExpenses: {
        name: t('dashboard.widget.graph.expenses'),
        amount: totalExpenses,
        colorVariant: totalExpenses <= 0 ? 'danger' : 'success',
      },
      result: {
        name: t('dashboard.widget.graph.result'),
        amount: result,
        colorVariant: result < 0 ? 'danger' : 'success',
      },
    }),
    [t, totalRevenue, totalExpenses, result],
  )

  return (
    <Widget
      title={t('dashboard.widget.graph.title', { currencyCode: currencyId })}
      subtitle={t('dashboard.widget.graph.title.subscript')}
      sideActions={<OverviewLabels isLoading={isLoading} stats={stats} />}
    >
      {isEmptyStateVisible ? (
        <EmptyStateImage image={emptyImageByLanguage[localeCode]} />
      ) : (
        <OverviewChart chartData={chartData} isLoading={isLoading} />
      )}
    </Widget>
  )
}
