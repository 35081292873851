import { getDefaultTransition, Spacing, zIndex } from '@design-system'

import styled from '@emotion/styled'

export const ToggleVisibilityWidgetButtonContainer = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: ${Spacing.S};
  right: ${Spacing.S};
  z-index: ${zIndex.Tooltip};
  ${getDefaultTransition('opacity')}
`
